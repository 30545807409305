<template>
  <el-dialog title="Cadastrar nova assinatura" :visible.sync="showDialog">
    <el-form
      :inline="true"
      ref="SubscriptionForm"
      label-position="left"
      :model="subscription"
      :rules="rules"
    >
      <el-form-item label="Aluno" label-width="100px">
        <el-input v-model="user.name" disabled />
      </el-form-item>
      <el-form-item label="Termina em" label-width="100px" prop="finish_at">
        <el-date-picker
          type="date"
          placeholder="Selecione uma data"
          v-model="subscription.finish_at"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
          style="width: 100%"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">Cancelar</el-button>
      <el-button type="primary" @click="submit"> Salvar </el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from "@/services/api";
import notify from "@/traits/notify";
import errors from "@/traits/errors";

export default {
  name: "SubscriptionForm",
  props: {
    showDialog: {
      required: true,
      type: Boolean,
    },
    user: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      subscription: {
        finish_at: "",
        user_id: this.user.id,
      },
      rules: {
        finish_at: {
          required: true,
          message: "É necessário informar a data de término da assinatura",
        },
      },
    };
  },
  methods: {
    closeDialog() {
      this.$emit("updateDialog", false);
    },
    submit() {
      this.$refs["SubscriptionForm"].validate((valid) => {
        if (valid) this.addSubscription();
      });
    },
    addSubscription() {
      api
        .createSubscription(
          this.subscription.finish_at,
          this.subscription.user_id
        )
        .then(() => {
          notify.sucessNotification(
            this,
            `Nova assinatura para o usuário ${this.user.name}`,
            "A data de termino de assinatura foi alterada"
          );
          this.$emit("updateDialog", false);
        })
        .catch((err) => errors.handleError(this, err));
    },
  },
};
</script>
