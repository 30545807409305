<template>
  <div class="container">
    <PageHeader title="Lista de módulos">
      <el-input
        v-model="query.keyword"
        placeholder="Buscar"
        style="width: 180px; margin: 10px 10px"
        @keyup.enter.native="handleFilter"
      />
      <el-button
        type="primary"
        icon="el-icon-search"
        style="width: 120px; margin: 10px 10px"
        @click="handleFilter"
        >Buscar</el-button
      >
    </PageHeader>
    <el-row
      v-if="hasPlaylistSortChanges"
      type="flex"
      justify="center"
      class="list-change-actions"
    >
      <el-button @click="undoPlaylistChanges">Desfazer alterações</el-button>
      <el-button type="primary" @click="savePlaylistSort"
        >Salvar Alterações</el-button
      >
    </el-row>
    <LoadingMessage
      v-if="loading"
      class="loading-message"
      style="margin-top: 200px"
    />
    <draggable
      v-else
      v-model="playlists"
      class="playlists-list"
      handle=".drag-indicator"
    >
      <BaseCard
        v-for="mod in playlists"
        :key="mod.id"
        :title="mod.title"
        :description="mod.description"
        :image="mod.image"
        :on-pressed="() => openPlaylistPage(mod.id)"
        :on-delete="() => handleDelete(mod.id)"
        :on-update="() => handleUpdate(mod.id)"
        :drag="true"
      />
    </draggable>
  </div>
</template>

<script>
import api from "@/services/api";
import notify from "@/traits/notify";
import errors from "@/traits/errors";
import BaseCard from "@/components/BaseCard";
import PageHeader from "@/components/PageHeader";
import LoadingMessage from "@/components/LoadingMessage";
import draggable from "vuedraggable";

export default {
  name: "ListPlaylists",
  components: { BaseCard, PageHeader, LoadingMessage, draggable },
  data() {
    return {
      loading: false,
      modules: [],
      sortedModules: [],
      query: {
        keyword: "",
      },
      showDeleteDialog: false,
    };
  },
  created() {
    this.fetchModules();
  },
  computed: {
    playlists: {
      get() {
        return this.sortedModules;
      },
      set(value) {
        console.log(value);
        this.sortedModules = value;
      },
    },
    hasPlaylistSortChanges: function () {
      return this.modules !== this.sortedModules;
    },
  },
  methods: {
    handleFilter() {
      this.query.page = 1;
      this.fetchModules();
    },
    fetchModules() {
      this.loading = true;
      api
        .getPlaylists(this.query)
        .then(({ data: playlists }) => {
          this.modules = playlists;
          this.playlists = playlists;
        })
        .catch((e) => errors.handleError(this, e))
        .finally(() => (this.loading = false));
    },
    consoleLog(str) {
      console.log(str);
    },
    handleDelete(moduleId) {
      api
        .deletePlaylist(moduleId)
        .then(() => {
          this.fetchModules();
          notify.sucessNotification(
            this,
            "Modulo excluído com sucesso",
            "Modulo inacessível para usuários"
          );
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    handleUpdate(moduleId) {
      this.$router.push({
        name: "UpdatePlaylist",
        params: { id: moduleId },
      });
    },
    handleDrag(list) {
      console.log(list);
    },
    undoPlaylistChanges() {
      this.sortedModules = this.modules;
    },
    savePlaylistSort() {
      const list = this.sortedModules.map((value, index) => ({
        id: value.id,
        index: index,
      }));
      api
        .sortPlaylist(list)
        .then(() => {
          this.modules = this.sortedModules;
          notify.sucessNotification(
            this,
            "Operação realizada",
            "Módulos ordenados com sucesso"
          );
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    openPlaylistPage(moduleId) {
      this.$router.push({
        name: "PlaylistPage",
        params: { id: moduleId },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  display: flex
  flex-direction: column
  flex-flow: wrap
  justify-content: center

.playlists-list
  align-items: flex-start
  justify-content: center
  display: flex
  width: 100%
  flex-flow: wrap
  max-width: 1300px

.list-change-actions
  width: 100%
  padding-bottom: 30px
</style>
