<template>
  <el-dialog :title="dialogTitle" :visible.sync="activeDialog">
    <el-form ref="UserForm" :model="user" :rules="rules">
      <el-form-item label="Nome" :label-width="formLabelWidth" prop="name">
        <el-input v-model="user.name" />
      </el-form-item>
      <el-form-item label="Email" :label-width="formLabelWidth" prop="email">
        <el-input type="email" v-model="user.email" />
      </el-form-item>
      <el-form-item label="Senha" :label-width="formLabelWidth" prop="password">
        <el-input type="password" v-model="user.password" />
      </el-form-item>
      <el-form-item label="Perfil" :label-width="formLabelWidth" prop="role">
        <el-select
          v-model="user.role"
          placeholder="Perfil"
          style="margin: 10px 10px"
        >
          <el-option
            v-for="role in roleArray"
            :key="role.key"
            :label="role.value"
            :value="role.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Telefone" :label-width="formLabelWidth" prop="phone">
        <el-input type="text" v-mask="'(##) #####-####'" v-model="user.phone" />
      </el-form-item>
      <el-form-item label="Cidade" :label-width="formLabelWidth" prop="city">
        <el-input type="text" v-model="user.city" />
      </el-form-item>
      <el-form-item label="Estado" :label-width="formLabelWidth" prop="state">
        <el-input type="text" v-model="user.state" />
      </el-form-item>
      <el-form-item label="Ativo" :label-width="formLabelWidth">
        <el-switch v-model="user.active" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="danger" @click="onDelete">Excluir usuário</el-button>
      <el-button @click="activeDialog = false">Cancelar</el-button>
      <el-button type="primary" @click="submit"> Enviar </el-button>
    </span>
  </el-dialog>
</template>

<script>
import authorization from "@/traits/authorization";
import notify from "@/traits/notify";
import errors from "@/traits/errors";
import api from "@/services/api";

export default {
  name: "UserForm",
  props: ["showDialog", "userId"],
  data() {
    return {
      isEdited: this.userId,
      user: {
        name: "",
        email: "",
        password: "",
        phone: "",
        city: "",
        state: "",
        role: "professor",
        active: true,
      },
      activeDialog: this.showDialog,
      formLabelWidth: "120px",
      roleArray: [
        {
          key: authorization.ROLE_ADMIN,
          value: "Administrador",
        },
        {
          key: authorization.ROLE_PROFESSOR,
          value: "Professor",
        },
        {
          key: authorization.ROLE_STUDENT,
          value: "Aluno",
        },
      ],
    };
  },
  computed: {
    dialogTitle() {
      return this.isEdited ? "Editar Usuário" : "Criar Usuário";
    },
    rules() {
      return {
        name: {
          required: true,
          message: "Digite seu nome",
          trigger: "blur",
        },
        email: [
          {
            required: true,
            message: "Digite um endereço de e-mail",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor, digite um e-mail válido",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Digite seu número de telefone",
            trigger: "blur",
            pattern: /^\(\d{2}\) \d{5}-\d{4}$/,
          },
        ],
        state: {
          required: true,
          message: "Digite seu Estado",
          trigger: "blur",
        },
        city: {
          required: true,
          message: "Digite sua cidade",
          trigger: "blur",
        },
        password: [
          {
            required: !this.isEdited,
            message: "Digite uma senha",
            trigger: "blur",
          },
          {
            min: 6,
            max: 20,
            message: "Senha deve ter entre 6 e 20 carácteres",
            trigger: "blur",
          },
        ],
      };
    },
  },
  created() {
    if (this.isEdited) this.fetchData(this.userId)
  },
  watch: {
    showDialog: function(val) {
      if (!val) this.$emit('updateDialog', false)
    },
    activeDialog: function(val) {
      if (!val) this.$emit('updateDialog', false)
    },
  },
  methods: {
    fetchData(userId) {
      this.user.role = "student";
      api
        .getUser(userId)
        .then(({ data }) => {
          this.user = {
            ...data.user,
            role: data.roles[0],
            active: !!data.user.active,
            phone: data.user.phone?.replace(
              /^(\d{2})(\d{5})(\d{4})$/,
              "($1) $2-$3"
            ),
          };
        })
        .catch((err) => errors.handleError(this, err))
        .finally(() => this.$forceUpdate());
    },
    submit() {
      this.$refs["UserForm"].validate((valid) => {
        if (valid) this.isEdited ? this.updateUser() : this.createUser();
      });
    },
    createUser() {
      const phone = this.user.phone.replace(/\D/g, "");
      api
        .createUser(
          this.user.name,
          this.user.email,
          this.user.password,
          phone,
          this.user.city,
          this.user.state,
          this.user.active,
          this.user.role
        )
        .then(() => {
          notify.sucessNotification(
            this,
            `Usuário ${this.user.name} Criado com sucesso`,
            "Se ele tiver ativo, já poderá acessar a plataforma"
          );
          this.resetForm();
          this.activeDialog = false;
          this.$emit("updateDialog", false);
        })
        .catch((err) => errors.handleError(this, err));
    },
    onDelete() {
      var message = `Tem certeza que deseja excluir o usuário(a) ${this.user.name}? Essa ação é permanente.`
      if (confirm(message) === true) {
        api
          .deleteUser(this.userId)
          .then(() => {
            this.$emit("updateDialog", false);
            notify.sucessNotification(
              this,
              `Usuário ${this.user.name} excluído com sucesso`,
              "Usuário e redações relacionadas foram deletadas"
            );
          })
          .catch((err) => {
            errors.handleError(this, err);
          });
      }
    },
    updateUser() {
      const phone = this.user.phone.replace(/\D/g, "");
      api
        .updateUser(
          this.user.id,
          this.user.name,
          this.user.email,
          this.user.password,
          phone,
          this.user.city,
          this.user.state,
          this.user.active,
          this.user.role
        )
        .then(() => {
          notify.sucessNotification(
            this,
            `Usuário ${this.user.name} alterado com sucesso`,
            "Se ele tiver ativo, já poderá acessar a plataforma"
          );
          this.resetForm();
          this.activeDialog = false;
          this.$emit("updateDialog", false);
        })
        .catch((err) => errors.handleError(this, err));
    },
    resetForm() {
      this.user.name = "";
      this.user.email = "";
      this.user.password = "";
      this.user.active = true;
      this.user.role = "professor";
    },
  },
};
</script>
