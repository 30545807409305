import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
import api from '../services/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: null,
    user: {},
    roles: [],
    permissions: [],
  },
  mutations: {
    updateAccessToken: (state, accessToken) => {
      Vue.axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
      state.accessToken = accessToken;
    },
    logout: (state) => {
      state.accessToken = null;
      state.user = {}
      state.roles = []
      state.permissions = []
    },
    updateUser: (state, user) =>{
      state.user = user;
    },
    updateRoles: (state, roles) =>{
      state.roles = roles;
    },
    updatePermissions: (state, permissions) =>{
      state.permissions = permissions;
    }
  },
  actions: {
    logout({ commit }) {
      localStorage.removeItem('accessToken');
      commit('logout');
      router.push('/entrar');
    },
    fetchAccessToken({ commit }) {
      commit('updateAccessToken', localStorage.getItem('accessToken'));
    },
    async fetchUser({ commit }){
      const { data} = await api.getMe()
      const user = data.user
      const roles = data.roles
      const permissions = data.permissions
      commit('updateUser', user)
      commit('updateRoles', roles)
      commit('updatePermissions', permissions)
    }
  },
  getters: {
    myState: state => {
      return state
    }
  },
  modules: {
  }
})
