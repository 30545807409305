<template>
  <vs-collapse class="content metrics">
    <vs-collapse-item>
      <div slot="header" class="metrics-header">
        <div>
          <h3>Minhas Métricas</h3>
        </div>
      </div>
      <vs-row
        vs-justify="space-around"
        vs-align="flex-start"
        class="metrics-content"
      >
        <div>
          <div>
            <el-select
              clearable
              v-model="query.created_at"
              placeholder="Selecione um período"
              style="width: 180px;margin: 0px 20px"
              @change="getMetrics"
            >
              <el-option
                v-for="period in periods"
                :key="period.key"
                :label="period.label"
                :value="period.key"
              />
            </el-select>
          </div>
          <div class="metrics-essays">
            <h4>Total de redações</h4>
            <h2>{{ total }}</h2>
          </div>
          <div class="metrics-essays">
            <h4>Pontuação média</h4>
            <h2>{{ scoreMean.toFixed(0) }}</h2>
          </div>
        </div>
        <div class="my-chart-class">
          <apexchart
            type="area"
            :options="chartScoreOptions"
            :series="seriesScore"
          />
        </div>
        <div class="my-chart-class">
          <apexchart
            type="area"
            :options="chartHistOptions"
            :series="seriesHist"
          />
        </div>
      </vs-row>
    </vs-collapse-item>
  </vs-collapse>
</template>

<script>
import api from '@/services/api.js'
import pt_br from 'apexcharts/dist/locales/pt-br.json'

export default {
  name: 'Metrics',
  props: ['showDialog', 'essayId'],
  data: function() {
    return {
      total: 0,
      scoreMean: 0,
      query: {
        total: 0,
        page: 1,
        limit: 10,
        created_at: '',
      },
      today: new Date(),
      periods: [
        {
          label: 'Últimos 30 dias',
          key: 1,
        },
        {
          label: 'Últimos 60 dias',
          key: 2,
        },
        {
          label: 'Últimos 90 dias',
          key: 3,
        },
      ],
      chartScoreOptions: {
        title: {
          text: 'Histórico de notas',
          align: 'left',
        },
        chart: {
          id: 'score',
          locales: [pt_br],
          defaultLocale: 'pt-br',
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          max: 1100,
          forceNiceScale: true,
          decimalsInFloat: 0,
        },
        xaxis: {
          type: 'datetime',
        },
      },
      seriesScore: [
        {
          name: 'Nota',
          data: [0, 0],
        },
      ],
      chartHistOptions: {
        title: {
          text: 'Histórico de envios',
          align: 'left',
        },
        chart: {
          id: 'shipments',
          locales: [pt_br],
          defaultLocale: 'pt-br',
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          forceNiceScale: true,
          tickAmount: 1,
          decimalsInFloat: 0,
        },
        xaxis: {
          type: 'datetime',
        },
      },
      seriesHist: [
        {
          name: 'Envios',
          data: [['2020-04', 34]],
        },
      ],
    }
  },
  created() {
    this.getMetrics()
  },
  methods: {
    getMetrics() {
      api
        .getMetrics(this.query)
        .then(({ data: essays }) => {
          var scoreTotal = 0
          var histData = {
            ['2020-01']: 0,
            ['2020-02']: 0,
            ['2020-03']: 0,
            ['2020-04']: 0,
            ['2020-05']: 0,
            ['2020-06']: 0,
            ['2020-07']: 0,
            ['2020-08']: 0,
            ['2020-09']: 0,
            ['2020-10']: 0,
            ['2020-11']: 0,
            ['2020-12']: 0,
          }
          var newData = {}
          var count = {}
          essays.forEach((essay) => {
            scoreTotal += essay.score
            const date = essay.created_at.split(' ')[0]
            const year = date.split('-')[0]
            const month = date.split('-')[1]
            if (histData[`${year}-${month}`]) {
              histData[`${year}-${month}`] += 1
            } else {
              histData[`${year}-${month}`] = 1
            }
            if (newData[date]) {
              newData[date] = Math.round(
                (newData[date] * count[date] + essay.score) / (count[date] + 1)
              )
              count[date] += 1
            } else {
              newData[date] = essay.score
              count[date] = 1
            }
          })
          if (!essays.length) {
            newData = {
              ['2020-01']: 0,
              ['2020-03']: 0,
              ['2020-05']: 0,
              ['2020-07']: 0,
              ['2020-09']: 0,
              ['2020-11']: 0,
            }
          }
          console.log(newData)
          console.log(Object.entries(histData))
          this.total = essays.length
          this.scoreMean = this.total == 0 ? 0 : scoreTotal / this.total
          this.seriesScore = [
            {
              name: 'Nota',
              data: Object.entries(newData),
            },
          ]
          this.seriesHist = [
            {
              name: 'Envios',
              data: Object.entries(histData),
            },
          ]
        })
        .catch((err) => console.log(err))
    },
  },
}
</script>

<style lang="sass" scoped>
.metrics
  flex-wrap: wrap
  max-width: 1400px
  margin-top: 50px
  padding: 20px 20px
  border-radius: 20px
  width: 90%

.metrics-header
  display: flex
  justify-content: space-between
  align-items: center

.metrics-content
  display: flex
  justify-content: space-around
  align-items: flex-end
  padding: 20px

.metrics-essays
  height: 90px
  width: 180px
  margin: 20px
  display: flex
  justify-content: space-around
  align-items: center
  flex-direction: column
  padding: 10px
  border-radius: 10px
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75)
  -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75)
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75)

.my-chart-class
  max-width: 420px
  width: 100%
</style>
