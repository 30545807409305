<template>
  <div>
    <ConfirmationDialog
      :showDialog="showDeleteDialog"
      :onDelete="onDelete"
      @updateDialog="() => setDeleteDialog(false)"
    />
    <div v-if="hasRole([roleAdmin])" class="button-options">
      <vs-button color="primary" type="gradient" @click="onUpdate">
        Editar
      </vs-button>
      <vs-button
        color="danger"
        type="gradient"
        @click="() => setDeleteDialog(true)"
      >
        Excluir
      </vs-button>
    </div>
    <div v-if="isChecked" class="button-options check-class" type="success">
      <i class="el-icon-check"></i>
    </div>
    <div style="padding: 15px" @click="onPressed">
      <vs-card actionable fixedHeight class="cardx">
        <div slot="header" style="display: flex, width: 100%">
          <div v-if="hasRole([roleAdmin]) && drag" class="drag-indicator">
            <vs-icon icon="drag_indicator"></vs-icon>
          </div>
          <div class="my-header-card">
            <h3>
              {{ title }}
            </h3>
          </div>
        </div>
        <div slot="media" style="max-height: 200px">
          <NwImg :src="image" alt="image" />
        </div>
        <div>
          <span>{{ descriptionResized }}</span>
        </div>
      </vs-card>
    </div>
  </div>
</template>

<script>
import authorization from "@/traits/authorization";
import ConfirmationDialog from "@/components/ConfirmationDialog";

export default {
  name: "BaseCard",
  components: { ConfirmationDialog },
  props: {
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
    image: {
      required: true,
      type: String,
    },
    isChecked: {
      required: false,
      type: Boolean,
      default: false,
    },
    onPressed: {
      required: true,
      type: Function,
    },
    onDelete: {
      required: true,
      type: Function,
    },
    onUpdate: {
      required: true,
      type: Function,
    },
    drag: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDeleteDialog: false,
    };
  },
  computed: {
    descriptionResized: function () {
      return this.description.substring(0, 255);
    },
    roleAdmin() {
      return authorization.ROLE_ADMIN;
    },
  },
  methods: {
    hasRole(roles) {
      return authorization.hasRole(roles);
    },
    setDeleteDialog(value) {
      this.showDeleteDialog = value;
    },
  },
};
</script>

<style lang="sass" scoped>
.cardx
  min-width: 220px
  max-width: 350px

.check-class
  padding: 5px
  height: 40px
  width: 40px
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  color: white
  background-color: #6de42b
  border-radius: 50%
  border: 3px solid white

.my-header-card
  display: flex
  width: 100%
  justify-content: space-between
  max-height: 200px

.router-default
  color: black

.button-options
  position: relative
  top: 0px
  z-index: 100
  float: right

.drag-indicator
  cursor: pointer
</style>
