import RouterUtils from './utils'
import EssayWithoutTheme from  '@/views/essays/EssayWithoutTheme'
import Essays from '@/views/essays/Essays'

export const essaysRoutes = [
  {
    path: '/livre',
    name: 'EssayWithoutTheme',
    component: EssayWithoutTheme,
    beforeEnter: (to, from, next) => {
      RouterUtils.checkUserActive() ? next() : next('/entrar')
    },
  },
  {
    path: '/redacoes',
    name: 'Essays',
    component: Essays,
    beforeEnter: (to, from, next) => {
      RouterUtils.checkUserActive() ? next() : next('/entrar')
    },
  },
]
