<template>
  <div class="container">
    <div class="content materials-content">
      <div align="left">
        <div class="page-header" align="left">
          <h2>Materiais para estudo</h2>
        </div>
      </div>
      <div v-if="materials.length" v-loading="loading">
        <vs-row vs-justify="center" vs-align="flex-start">
          <material-card
            v-on:updatelist="getList"
            v-for="material in materials"
            :material="material"
            :key="material.id"
          />
        </vs-row>
      </div>
      <div v-if="loading" v-loading="loading">
        <vs-row vs-justify="center" vs-align="flex-start">
          <material-card
            v-on:updatelist="getList"
            v-for="x in ['key1', 'key2', 'key3']"
            :material="materialExample"
            :key="x"
          />
        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialCard from './components/MaterialCard'
import api from '../../services/api'
import errors from '../../traits/errors'

export default {
  name: 'Materials',
  components: {
    MaterialCard,
  },
  data() {
    return {
      materials: [],
      loading: false,
      materialExample: {
        title: 'Carregando',
        description: 'Carregando',
        file: 'Carregando',
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      this.loading = true
      api
        .getMaterials()
        .then(({ data: materials }) => {
          this.loading = false
          this.materials = materials
        })
        .catch((err) => {
          this.loading = false
          errors.handleError(this, err)
        })
    },
    handleLoading() {
      this.loading = !this.loading
    },
  },
}
</script>

<style lang="sass" scoped>
.container
  min-height: 89vh
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.materials-content
  width: 90%
  margin: 20px
  max-width: 1000px

.themes
  margin: auto

.page-header
  padding: 10px
  border-radius: 20px
</style>
