<template>
  <div>
    <Sidebar v-bind:show="active" />
    <el-row class="nav-bar" type="flex" justify="space-between" align="middle">
      <el-col :span="20">
        <el-row type="flex" justify="start" align="middle">
          <div v-if="isLogged" class="sidebar-button" @click="active = !active">
            <span>Abrir Menu </span>
          </div>
          <div v-if="isLogged" class="title-divider"></div>
          <div class="title" @click="toHomePage">
            <i
              class="el-icon-notebook-1"
              style="padding-right: 5px; padding-top: 2px"
            />
            <span>Correção On-line</span>
          </div>
        </el-row>
      </el-col>
      <el-col :span="4">
        <div @click="toUserProfile" v-if="isLogged">
          <el-row type="flex" justify="end" align="middle" class="user-info">
            <img
              class="user-avatar"
              width="40px"
              src="https://image.freepik.com/vetores-gratis/perfil-de-avatar-de-homem-no-icone-redondo_24640-14044.jpg"
            />
            <span class="user-name hidden-xs-only">{{ userName }}</span>
          </el-row>
        </div>
        <div v-else>
          <el-row type="flex" justify="end" align="middle">
            <div @click="toLoginPage" class="auth-button">
              <span>Entrar</span>
            </div>
            <div @click="toRegisterPage" class="auth-button">
              <span>Cadastro</span>
            </div>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Sidebar from './Sidebar'
import 'element-ui/lib/theme-chalk/display.css'

export default {
  name: 'Navbar',
  components: {
    Sidebar,
  },
  data: () => ({
    colorx: '#1db952',
    indexActive: 0,
    active: true,
  }),
  computed: {
    isLogged() {
      return this.$store.state.accessToken !== null
    },
    userName() {
      if (this.$store.state.user.name === undefined) return 'Usuário'
      return this.$store.state.user.name.split(' ')[0]
    },
  },
  methods: {
    toUserProfile() {
      this.$router.push({ name: 'UserProfile' })
    },
    toHomePage() {
      this.$router.push({ name: 'HomePage' })
    },
    toLoginPage() {
      this.$router.push({ name: 'Login' })
    },
    toRegisterPage() {
      this.$router.push({ name: 'Register' })
    },
  },
}
</script>

<style lang="sass" scoped>
.nav-bar
  background: rgb(0,0,0)
  color: rgb(255,255,255)
  height: 50px

.sidebar-button
  padding: 10px
  cursor: pointer

.title-divider
  height: 25px
  width: 2px
  background-color: white

.title
  padding: 10px
  font-size: large
  font-weight: 600
  cursor: pointer

.user-info
  cursor: pointer
  padding: 10px

.user-avatar
  border-radius: 50%

.user-name
  padding: 10px

.auth-button
  color: rgb(255,255,255)
  padding: 10px
  cursor: pointer
  font-weight: 600

  &:hover
    transition: all 0.3s
    font-size: larger
</style>
