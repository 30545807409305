import RouterUtils from './utils'
import Materials from '@/views/materials/Materials'
import CreateMaterial from '@/views/materials/CreateMaterial'

export const materialsRoutes = [
  {
    path: '/materiais',
    name: 'Materials',
    component: Materials,
    beforeEnter: (to, from, next) => {
      RouterUtils.checkUserActive() ? next() : next('/entrar')
    },
  },
  {
    path: '/materiais/novo',
    name: 'CreateMaterial',
    component: CreateMaterial,
    beforeEnter: (to, from, next) => {
      if (RouterUtils.checkUserActive()) {
        RouterUtils.checkUserIsAdmin() ? next() : next('/')
      } else {
        next('/entrar')
      }
    },
  },
  {
    path: '/materiais/editar/:id',
    name: 'EditMaterial',
    component: CreateMaterial,
    props: true,
    beforeEnter: (to, from, next) => {
      if (RouterUtils.checkUserActive()) {
        RouterUtils.checkUserIsAdmin() ? next() : next('/')
      } else {
        next('/entrar')
      }
    },
  },
]
