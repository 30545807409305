<template>
  <div style="margin: 10px" align="right">
    <div style="margin-bottom: -20px">
      <vs-button
        v-if="hasRole([roleAdmin])"
        style="margin-left: 5px"
        color="primary"
        type="gradient"
        @click="handleEdit"
        >Editar</vs-button
      >
      <vs-button
        v-if="hasRole([roleAdmin])"
        style="margin-left: 5px"
        color="danger"
        type="gradient"
        @click="handleDelete"
        >Excluir</vs-button
      >
      <el-tooltip effect="dark" content="Baixar material" placement="top">
        <el-button
          style="margin-left: 5px"
          type="success"
          icon="el-icon-download"
          circle
          @click="handleDownload"
        ></el-button>
      </el-tooltip>
    </div>
    <div class="material-card-card" align="left">
      <div class="material-card-container" align="center">
        <div class="material-card-content" align="center">
          <p class="material-card-text">{{ material.title }}</p>
        </div>
      </div>
      <div class="material-card-description">
        <p>
          {{ material.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../services/api'
import notify from '../../../traits/notify'
import errors from '../../../traits/errors'
import authorization from '../../../traits/authorization'

export default {
  name: 'MaterialCard',
  props: ['material'],
  computed: {
    userName() {
      return this.$store.state.user.name
    },
    roleAdmin() {
      return authorization.ROLE_ADMIN
    },
    roleStudent() {
      return authorization.ROLE_STUDENT
    },
    roleProfessor() {
      return authorization.ROLE_PROFESSOR
    },
  },
  methods: {
    handleDelete() {
      api
        .deleteMaterial(this.material.id)
        .then(() => {
          this.$emit('updatelist')
          notify.sucessNotification(
            this,
            'Material excluído com sucesso',
            'Material inacessível para usuários'
          )
        })
        .catch((err) => {
          errors.handleError(this, err)
        })
    },
    handleEdit() {
      this.$router.push({
        name: 'EditMaterial',
        params: { id: this.material.id },
      })
    },
    handleDownload() {
      window.open(this.material.file, '_blank')
    },
    hasRole(roles) {
      return authorization.hasRole(roles)
    },
  },
}
</script>

<style lang="sass" scoped>
.material-card-card
  max-width: 260px
  margin: 10px
  border: 3px solid white
  border-radius: 10px
  background: white
  -webkit-box-shadow: -13px 16px 5px 0px rgba(0,0,0,0.5)
  -moz-box-shadow: -13px 16px 5px 0px rgba(0,0,0,0.5)
  box-shadow: -0px 0px 15px 2px rgba(0,0,0,0.5)

.material-card-container
  height: 250px
  min-width: 250px
  align-items: center
  text-align: center
  display: flex
  flex-direction: column
  transition: all 0.5s
  border-radius: 10px
  background-size: 170%
  background-repeat: no-repeat
  background-position: center
  background-image: url('~@/assets/notebook_material.jpg')

  &:hover
    transition: all 0.5s
    background-size: 190%

.material-card-content
  height: 60%
  width: 60%
  padding: 10px
  background: orange
  position: relative
  margin: auto
  align-items: center
  text-align: center
  justify-content: center
  display: flex
  transform: skewX(7deg)
  transition: all 0.5s
  -ms-transform: skewX(7deg)
  -webkit-transform: skewX(7deg)
  -webkit-box-shadow: -13px 16px 5px 0px rgba(0,0,0,0.5)
  -moz-box-shadow: -13px 16px 5px 0px rgba(0,0,0,0.5)
  box-shadow: -13px 16px 5px 0px rgba(0,0,0,0.5)

.material-card-text
  font-size: 20px
  font-weight: bold
  font-family: "Segoe UI", "Arial", sans-serif
  transform: skewX(-7deg)
  -ms-transform: skewX(-7deg)
  -webkit-transform: skewX(-7deg)

.material-card-description
  padding: 10px
  font-size: 15px
  font-family: "Segoe UI", "Arial", sans-serif

.material-card-icon
  border-radius: 10px
  transform: skewX(-7deg)
  -ms-transform: skewX(-7deg)
  -webkit-transform: skewX(-7deg)
</style>
