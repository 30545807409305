<template>
  <el-row class="grade-container">
    <el-col :span=12 class="grade-title"> Nota Final </el-col>
    <el-col :span=12 class="grade-content">
      {{ grade }}
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "FinalGrade",
  props: {
    grade: {
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.grade-container
  margin: 10px
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.3)
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.3)
  border-radius: 5px 5px 5px 5px

.grade-title
  background: #0767ca
  padding: 5px
  border-radius: 5px 0px 0px 5px
  color: white
  font-weight: bold
  text-align: center
  font-size: 20px

.grade-content
  background: white
  color: #4C5150
  padding: 5px
  border-radius: 0px 5px 5px 0px
  white-space: pre-line
  text-align: center
  font-size: 20px
  font-weight: bold
</style>