<template>
  <div>
    <BaseCard
      :title="theme.title"
      :description="theme.subtitle"
      :image="theme.image"
      :on-pressed="() => onPressed(theme.id)"
      :on-delete="() => onDelete(theme.id)"
      :on-update="() => onUpdate(theme.id)"
      :is-checked="themesMade.includes(theme.id)"
    />
  </div>
</template>

<script>
import api from "../../../services/api";
import notify from "../../../traits/notify";
import errors from "../../../traits/errors";
import BaseCard from "@/components/BaseCard";

export default {
  name: "ThemeCard",
  props: ["theme", "themesMade"],
  components: { BaseCard },
  methods: {
    onPressed(themeId) {
      this.$router.push({ name: "ViewTheme", params: { id: themeId } });
    },
    onDelete(themeId) {
      api
        .deleteTheme(themeId)
        .then(() => {
          this.$emit("updatelist");
          notify.sucessNotification(
            this,
            "Tema excluído com sucesso",
            "Tema inacessível para usuários"
          );
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    onUpdate(themeId) {
      this.$router.push({
        name: "EditTheme",
        params: { id: themeId },
      });
    },
  },
};
</script>
