,<template>
  <div>
    <el-row>
      <PerformanceCard
        title="Desempenho geral"
        subtitle="Histórico de pontuação"
        :data="score"
        :max="1000"
      />
      <PerformanceCard
        title="Competência 1"
        subtitle="Histórico na competência 1"
        :data="competence1"
      />
    </el-row>
    <el-row>
      <PerformanceCard
        title="Competência 2"
        subtitle="Histórico na competência 2"
        :data="competence2"
      />
      <PerformanceCard
        title="Competência 3"
        subtitle="Histórico na competência 3"
        :data="competence3"
      />
    </el-row>
    <el-row>
      <PerformanceCard
        title="Competência 4"
        subtitle="Histórico na competência 4"
        :data="competence4"
      />
      <PerformanceCard
        title="Competência 5"
        subtitle="Histórico na competência 5"
        :data="competence5"
      />
    </el-row>
  </div>
</template>

<script>
import PerformanceCard from "./PerformanceCard";

export default {
  name: "UserMetrics",
  props: {
    essays: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  components: {
    PerformanceCard,
  },
  computed: {
    score: function () {
      return this.essays
        .slice(0, 6)
        .filter((e) => e.competence_1)
        .map((e) => this.calculateScore(e));
    },
    competence1: function () {
      return this.essays
        .slice(0, 6)
        .filter((e) => e.competence_1)
        .map((e) => e.competence_1);
    },
    competence2: function () {
      return this.essays
        .slice(0, 6)
        .filter((e) => e.competence_2)
        .map((e) => e.competence_2);
    },
    competence3: function () {
      return this.essays
        .slice(0, 6)
        .filter((e) => e.competence_3)
        .map((e) => e.competence_3);
    },
    competence4: function () {
      return this.essays
        .slice(0, 6)
        .filter((e) => e.competence_4)
        .map((e) => e.competence_4);
    },
    competence5: function () {
      return this.essays
        .slice(0, 6)
        .filter((e) => e.competence_5)
        .map((e) => e.competence_5);
    },
  },
  methods: {
    calculateScore(essay) {
      return (
        essay.competence_1 +
        essay.competence_2 +
        essay.competence_3 +
        essay.competence_4 +
        essay.competence_5
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.content
  border-radius: 20px
  padding: 20px 20px
</style>
