import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

export default class api {
  // Auth
  static async getMe() {
    return await Vue.axios.get('/me');
  }

  static async updateMe(name, email, password, phone, city, state) {
    return await Vue.axios.put('/me', {
      name,
      email,
      password,
      phone,
      city,
      state
    });
  }

  static async register(name, email, password, phone, state, city) {
    return await Vue.axios.post('/register', {
      name,
      email,
      password,
      phone,
      state,
      city
    });
  }

  static async authenticate(email, password) {
    return await Vue.axios.post('/authenticate', { email, password });
  }

  // Theme
  static async getThemes(query = { keyword: '', status: '' }) {
    return await Vue.axios.get('/themes', { params: query });
  }

  static async getThemesMadeByMe() {
    return await Vue.axios.get('/themes_made');
  }

  static async getTheme(themeId) {
    return await Vue.axios.get(`/themes/${themeId}`);
  }

  static async createTheme(title, content, subtitle, image, related_video_id) {
    return await Vue.axios.post(`/themes`, { title, content, subtitle, image, related_video_id });
  }

  static async deleteTheme(themeId) {
    return await Vue.axios.delete(`/themes/${themeId}`);
  }

  static async updateTheme(themeId, title, content, subtitle, image, related_video_id) {
    return await Vue.axios.put(`/themes/${themeId}`, {
      title,
      content,
      subtitle,
      image,
      related_video_id
    });
  }

  // Essay

  static async getEssays(query = { page: 1, limit: 10, keyword: '' }) {
    return await Vue.axios.get('/essays', { params: query });
  }

  static async getEssaysMadeByUser(userId) {
    return await Vue.axios.get(`/essays/user-made/${userId}`);
  }

  static async getMyEssays() {
    return await Vue.axios.get(`/essays/my`);
  }

  static async getMetrics(query = { page: 1, limit: 10, keyword: '' }) {
    return await Vue.axios.get('/essays-metrics', { params: query });
  }

  static async getEssay(essayId) {
    return await Vue.axios.get(`/essays/${essayId}`);
  }

  static async createEssay(file, comment, theme_id, material, material_title) {
    return await Vue.axios.post(`/essays`, {
      file,
      comment,
      theme_id,
      material,
      material_title
    });
  }

  static async deleteEssay(essayId) {
    return await Vue.axios.delete(`/essays/${essayId}`);
  }

  static async updateEssay(
    essayId,
    competence_1,
    competence_1_description,
    competence_2,
    competence_2_description,
    competence_3,
    competence_3_description,
    competence_4,
    competence_4_description,
    competence_5,
    competence_5_description,
    correction,
    audio
  ) {
    return await Vue.axios.put(`/essays/${essayId}`, {
      competence_1,
      competence_1_description,
      competence_2,
      competence_2_description,
      competence_3,
      competence_3_description,
      competence_4,
      competence_4_description,
      competence_5,
      competence_5_description,
      correction,
      audio
    });
  }

  static async updateCorrection(
    essayId,
    competence_1,
    competence_1_description,
    competence_2,
    competence_2_description,
    competence_3,
    competence_3_description,
    competence_4,
    competence_4_description,
    competence_5,
    competence_5_description,
    correction,
    audio
  ) {
    return await Vue.axios.put(`/essays/${essayId}/update`, {
      competence_1,
      competence_1_description,
      competence_2,
      competence_2_description,
      competence_3,
      competence_3_description,
      competence_4,
      competence_4_description,
      competence_5,
      competence_5_description,
      correction,
      audio
    });
  }

  static async arrestEssay(essayId) {
    return await Vue.axios.put(`/essays/${essayId}/arrest`, {});
  }

  static async unlinkCorrector(essayId) {
    return await Vue.axios.put(`/essays/${essayId}/unlink`, {});
  }

  // Users
  static async getUser(userId) {
    return await Vue.axios.get(`/users/${userId}`);
  }

  // Users
  static async getPublicUser(userId) {
    return await Vue.axios.get(`/users/${userId}/public`);
  }

  static async getUsers(
    query = { page: 1, limit: 10, keyword: '', active: undefined, role: '' }
  ) {
    return await Vue.axios.get('/users', { params: query });
  }

  static async createUser(
    name,
    email,
    password,
    phone,
    city,
    state,
    active,
    role
  ) {
    return await Vue.axios.post('/users', {
      name,
      email,
      password,
      phone,
      city,
      state,
      active,
      role
    });
  }

  static async updateUser(
    userId,
    name,
    email,
    password,
    phone,
    city,
    state,
    active,
    role
  ) {
    return await Vue.axios.put(`/users/${userId}`, {
      name,
      email,
      password,
      phone,
      city,
      state,
      active,
      role
    });
  }

  static async deleteUser(userId) {
    return await Vue.axios.delete(`/users/${userId}`);
  }

  // Credits
  static async updateCredits(userId, credits, monitor_credits) {
    return await Vue.axios.put(`/credits`, { userId, credits, monitor_credits });
  }

  // Materials
  static async getMaterials() {
    return await Vue.axios.get('/materials');
  }

  static async getMaterial(materialId) {
    return await Vue.axios.get(`/materials/${materialId}`);
  }

  static async createMaterial(title, description, file) {
    return await Vue.axios.post(`/materials`, { title, description, file });
  }

  static async deleteMaterial(materialId) {
    return await Vue.axios.delete(`/materials/${materialId}`);
  }

  static async updateMaterial(materialId, title, description, file) {
    return await Vue.axios.put(`/materials/${materialId}`, {
      title,
      description,
      file
    });
  }

  // Uploader
  static async deleteFile(url) {
    return await Vue.axios.post(`/upload/delete`, { url });
  }

  // Reports
  static async getCreditsReports(
    query = { page: 1, limit: 10, keyword: '', action: '' }
  ) {
    return await Vue.axios.get('/reports/credits', { params: query });
  }

  // Reports
  static async getEssaysReports(
    query = { page: 1, limit: 10, keyword: '', action: '' }
  ) {
    return await Vue.axios.get('/reports/essays', { params: query });
  }

  // Config
  static async getEssaySubmissionStatus() {
    return await Vue.axios.get('/config/get-essay-submission-status');
  }

  static async getConfig() {
    return await Vue.axios.get('/config');
  }

  static async setConfig(config) {
    return await Vue.axios.put('/config', config);
  }

  // Video Modules
  static async getPlaylists(query = { keyword: '' }) {
    return await Vue.axios.get('/playlists', { params: query });
  }

  static async getPlaylist(playlistId) {
    return await Vue.axios.get(`/playlists/${playlistId}`);
  }

  static async createPlaylist(title, description, image, duration) {
    return await Vue.axios.post(`/playlists`, {
      title,
      description,
      image,
      duration
    });
  }

  static async deletePlaylist(playlistId) {
    return await Vue.axios.delete(`/playlists/${playlistId}`);
  }

  static async updatePlaylist(playlistId, title, description, image, duration) {
    return await Vue.axios.put(`/playlists/${playlistId}`, {
      title,
      description,
      image,
      duration
    });
  }

  static async sortPlaylist(newList) {
    return await Vue.axios.put(`/playlists/sort`, { list: newList });
  }

  // Videos
  static async getVideos(query = { keyword: '', playlist_id: '' }) {
    return await Vue.axios.get('/videos', { params: query });
  }

  static async getVideo(videoId) {
    return await Vue.axios.get(`/videos/${videoId}`);
  }

  static async getVideosWatchedByMe() {
    return await Vue.axios.get(`/videos/watched`);
  }

  static async createVideo(
    title,
    description,
    image,
    vimeo_id,
    material,
    playlist_id
  ) {
    return await Vue.axios.post(`/videos`, {
      title,
      description,
      image,
      vimeo_id,
      material,
      playlist_id
    });
  }

  static async deleteVideo(videoId) {
    return await Vue.axios.delete(`/videos/${videoId}`);
  }

  static async updateVideo(
    videoId,
    title,
    description,
    image,
    vimeo_id,
    material,
    playlist_id
  ) {
    return await Vue.axios.put(`/videos/${videoId}`, {
      title,
      description,
      image,
      vimeo_id,
      material,
      playlist_id
    });
  }

  static async watchVideo(videoId) {
    return await Vue.axios.put(`/videos/${videoId}/watch`, {});
  }

  // Subscriptions
  static async createSubscription(finish_at, user_id) {
    return await Vue.axios.post(`/subscriptions`, { finish_at, user_id });
  }
}
