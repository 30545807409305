<template>
  <div class="container">
    <div>
      <el-menu
        :default-active="activeIndex"
        class="menu"
        active-text-color="#409EFF"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">{{ infoTitle }}</el-menu-item>
        <el-menu-item index="2">{{ statisticsTitle }}</el-menu-item>
      </el-menu>
    </div>
    <el-row v-show="activeIndex === '1'">
      <el-col :xs="24" :sm="24" :md="12">
        <UserInfo v-if="userId" :id="userId" />
      </el-col>
      <el-col :xs="24" :sm="24" :md="12">
        <ThemesMadeByUser :loading="loading" :essays="essays" />
      </el-col>
    </el-row>
    <el-row v-if="activeIndex === '2'">
      <el-col :span="24">
        <UserMetrics :essays="reversedArray" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UserInfo from "./components/UserInfo";
import UserMetrics from "./components/UserMetrics";
import ThemesMadeByUser from "./components/ThemesMadeByUser";
import api from "@/services/api";
import errors from "@/traits/errors";

export default {
  name: "UserPage",
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  components: { UserInfo, UserMetrics, ThemesMadeByUser },
  data() {
    return {
      loading: false,
      activeName: "first",
      activeIndex: "1",
      essays: [],
    };
  },
  created() {
    this.getList();
  },
  watch: {
    id: function () {
      this.getList();
    },
  },
  computed: {
    userId: function () {
      if (this.id) return Number(this.id);
      return Number(this.$store.state.user.id);
    },
    yourselfPage: function () {
      return this.$store.state.user.id === Number(this.userId);
    },
    infoTitle: function () {
      if (this.yourselfPage) return "Minhas informações";
      return "Informações do usuário";
    },
    statisticsTitle: function () {
      if (this.yourselfPage) return "Minhas estatísticas";
      return "Estatísticas do usuário";
    },
    reversedArray: function () {
      return this.essays.slice(0,6).reverse()
    }
  },
  methods: {
    handleSelect(key) {
      this.activeIndex = key;
    },
    getList() {
      this.loading = true;
      this.yourselfPage ? this.getMyEssays() : this.getEssaysMadeByUser();
    },
    getEssaysMadeByUser() {
      api
        .getEssaysMadeByUser(this.userId)
        .then(({ data }) => {
          this.essays = data;
        })
        .catch((err) => {
          errors.handleError(this, err);
        })
        .finally(() => (this.loading = false));
    },
    getMyEssays() {
      api
        .getMyEssays()
        .then(({ data }) => {
          this.essays = data;
        })
        .catch((err) => {
          errors.handleError(this, err);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  max-width: 1400px
  margin: auto

.menu
  margin: 0px 20px
  border-radius: 0px 0px 10px 10px
  text-align: center
  display: flex
  justify-content: center
</style>
