<template>
  <div>
    <div class="theme-card" @click="onPressed">
      <div>
        <div class="image-container">
          <NwImg :src="theme.image" alt="Imagem do Tema" class="image-theme" />
          <div v-if="result" class="result-content">
            <i class="el-icon-edit" />
            <p style="margin-left: 5px">
              {{ result }}
            </p>
          </div>
        </div>
      </div>
      <div class="theme-details">
        <el-row>
          <h3>{{ theme.title }}</h3>
        </el-row>
        <el-row type="flex" align="bottom">
          <el-col :span="24">
            <p style="font-size: 15px; margin-bottom: 5px">
              {{ theme.subtitle }}
            </p>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThemeCardMini",
  props: {
    theme: {
      type: Object,
      required: true,
    },
    onPressed: {
      type: Function,
      required: true,
    },
    result: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="sass" scoped>
.image-theme
  width: 100%
  border-radius: 10px
  margin-right: 15px
  height: 120px
  object-fit: cover

.image-container
  position: relative
  text-align: center
  color: white

.theme-card
  height: 250px
  min-width: 200px
  padding: 5px
  margin: 5px
  border-radius: 10px
  background-color: white
  color: #4C5150
  -webkit-box-shadow: 0px 5px 30px -20px rgba(0,0,0,0.5)
  -moz-box-shadow: 0px 5px 30px -20px rgba(0,0,0,0.5)
  box-shadow: 0px 5px 30px -20px rgba(0,0,0,0.5)

  &:hover
    transition: all 0.5s
    cursor: pointer
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.5)
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.5)
    box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.5)

.theme-details
  height: 120px
  padding: 5px 15px
  display: flex
  justify-content: space-between
  flex-direction: column
  overflow: hidden

.button-options
  position: relative
  top: 10px
  z-index: 100
  float: right

.result-content
  position: absolute
  top: 5px
  right: 5px
  background-color: #409EFF
  color: white
  text-align: center
  padding: 4px 8px
  margin-bottom: 2px
  border-radius: 10px
  font-size: 13px
  font-weight: 600
  display: flex
  align-items: center
  border: 2px solid white
</style>
