import RouterUtils from './utils';
import ListPlaylists from '@/views/playlists/ListPlaylists';
import PlaylistPage from '@/views/playlists/PlaylistPage';
import PlaylistForm from '@/views/playlists/PlaylistForm';
import VideoPage from '@/views/videos/VideoPage';
import VideoForm from '@/views/videos/VideoForm';
import ListVideos from '@/views/videos/ListVideos';

export const videosRoutes = [
  {
    path: '/modulos',
    name: 'ListPlaylists',
    component: ListPlaylists,
    beforeEnter: (to, from, next) => {
      RouterUtils.checkUserActive() ? next() : next('/entrar');
    }
  },
  {
    path: '/modulos/form',
    name: 'CreatePlaylist',
    component: PlaylistForm,
    beforeEnter: (to, from, next) => {
      if (RouterUtils.checkUserActive()) {
        RouterUtils.checkUserIsAdmin() ? next() : next('/entrar');
      } else {
        next('/entrar');
      }
    }
  },
  {
    path: '/modulos/form/:id',
    name: 'UpdatePlaylist',
    component: PlaylistForm,
    props: true,
    beforeEnter: (to, from, next) => {
      if (RouterUtils.checkUserActive()) {
        RouterUtils.checkUserIsAdmin() ? next() : next('/entrar');
      } else {
        next('/entrar');
      }
    }
  },
  {
    path: '/modulos/:id',
    name: 'PlaylistPage',
    component: PlaylistPage,
    props: true,
    beforeEnter: (to, from, next) => {
      RouterUtils.checkUserActive() ? next() : next('/entrar');
    }
  },
  {
    path: '/videos',
    name: 'ListVideos',
    component: ListVideos,
    beforeEnter: (to, from, next) => {
      RouterUtils.checkUserActive() ? next() : next('/entrar');
    }
  },
  {
    path: '/videos/form',
    name: 'CreateVideo',
    component: VideoForm,
    props: true,
    beforeEnter: (to, from, next) => {
      if (RouterUtils.checkUserActive()) {
        RouterUtils.checkUserIsAdmin() ? next() : next('/entrar');
      } else {
        next('/entrar');
      }
    }
  },
  {
    path: '/videos/form/:id',
    name: 'UpdateVideo',
    component: VideoForm,
    props: true,
    beforeEnter: (to, from, next) => {
      if (RouterUtils.checkUserActive()) {
        RouterUtils.checkUserIsAdmin() ? next() : next('/entrar');
      } else {
        next('/entrar');
      }
    }
  },
  {
    path: '/videos/:id',
    name: 'VideoPage',
    component: VideoPage,
    props: true,
    beforeEnter: (to, from, next) => {
      RouterUtils.checkUserActive() ? next() : next('/entrar');
    }
  }
];
