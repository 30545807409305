<template>
  <div class="container">
    <PageHeader title="Lista de temas">
      <el-input
        v-model="query.keyword"
        placeholder="Buscar"
        style="width: 180px; margin: 10px 10px"
        @keyup.enter.native="handleFilter"
      />
      <el-select
        v-model="query.status"
        placeholder="Status"
        clearable
        style="width: 180px; margin: 10px 10px"
        @change="handleFilter"
      >
        <el-option
          v-for="[key, label] in Object.entries(status)"
          :key="key"
          :label="label"
          :value="key"
        />
      </el-select>
      <el-button
        type="primary"
        icon="el-icon-search"
        style="width: 120px; margin: 10px 10px"
        @click="handleFilter"
        >Buscar</el-button
      >
    </PageHeader>
    <div v-if="loading" class="themes-loading">
      <i class="el-icon-loading" />
      Carregando ...
    </div>
    <div v-else class="themes-list">
      <div v-if="themes.length == 0" class="themes-loading">
        <i class="el-icon-warning-outline" />
        Nenhum resultado para sua pesquisa
      </div>
      <theme-card
        v-on:updatelist="getList"
        v-for="theme in themes"
        :theme="theme"
        :themes-made="themesMade"
        :key="theme.id"
      />
    </div>
  </div>
</template>

<script>
import ThemeCard from "./components/ThemeCard";
import api from "@/services/api";
import errors from "@/traits/errors";
import PageHeader from "@/components/PageHeader";

export default {
  name: "Themes",
  components: {
    ThemeCard,
    PageHeader,
  },
  data() {
    return {
      query: {
        keyword: "",
        status: "",
      },
      status: {
        made: "Enviados",
        notMade: "Não enviados",
      },
      themes: [],
      themesMade: [],
      loading: false,
    };
  },
  created() {
    this.getList();
    this.getThemesMade();
  },
  methods: {
    handleFilter() {
      this.query.page = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      api
        .getThemes(this.query)
        .then(({ data: themes }) => {
          this.themes = themes;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          errors.handleError(this, err);
        });
    },
    getThemesMade() {
      api
        .getThemesMadeByMe()
        .then(({ data: themes }) => {
          this.themesMade = themes.map((theme) => theme.id);
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  display: flex
  flex-direction: column
  flex-flow: wrap
  justify-content: center

.themes
  margin: auto

.themes-loading
  margin-top: 100px
  text-align: center
  color: white
  font-size: 22px
  width: 100%

.themes-list
  align-items: flex-start
  justify-content: center
  display: flex
  width: 100%
  flex-flow: wrap
  max-width: 1300px

.search-bar-content
  width: 90%
  max-width: 1300px
  background-color: white
  border-radius: 0px 0px 10px 10px
  padding: 10px
  margin: 0px 0px 20px 0px
</style>
