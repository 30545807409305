<template>
  <div class="container">
    <Essay-Form
      v-on:updateDialog="handleDialog"
      :theme-id="theme.id"
      :show-dialog="showDialog"
    />
    <div class="content theme-content">
      <div class="content-title">
        <div class="content-title-description">
          <h2>{{ theme.title }}</h2>
          <p style="font-size: 17px">
            {{ theme.subtitle }}
          </p>
        </div>
        <div class="content-image">
          <NwImg :src="theme.image" alt="Imagem do Tema" class="theme-image" />
        </div>
      </div>
      <vs-divider style="padding: 0px 10px" />
      <vs-row vs-justify="center">
        <PDFViewer
          v-if="theme.content"
          :url="theme.content"
          :downloadAllowed="true"
          downloadPosition="top"
        />
      </vs-row>
      <vs-row vs-justify="center">
        <el-button
          type="primary"
          @click="showDialog = true"
          :disabled="handleCredits || !submissionAvailable"
        >
          Envie sua Redação
        </el-button>
        <el-button
          type="primary"
          @click="() => goToVideoPage(theme.related_video_id)"
          :disabled="!theme.related_video_id"
        >
          Acessar discussão temática
        </el-button>
      </vs-row>
      <vs-row vs-justify="center" v-if="handleCredits">
        <span class="credits-alert">Saldo de créditos insuficiente</span>
      </vs-row>
      <br />
      <vs-row vs-justify="center" v-if="!submissionAvailable">
        <span class="credits-alert">Envio de redações suspenso</span>
      </vs-row>
    </div>
  </div>
</template>

<script>
import api from "../../services/api";
import errors from "../../traits/errors";
import EssayForm from "./components/EssayForm";
import PDFViewer from "@/components/PDFViewer.vue";

export default {
  props: ["id"],
  components: {
    EssayForm,
    PDFViewer,
  },
  data() {
    return {
      theme: {
        content: undefined,
        subtitle: "",
        title: "",
        image: "",
        related_video_id: null,
      },
      showDialog: false,
      submissionAvailable: true,
    };
  },
  created() {
    this.fetchData(this.id);
    this.fetchSubmissionStatus();
  },
  computed: {
    formattedZoom() {
      return Number.parseInt(this.scale * 100 * 2);
    },
    handleCredits() {
      if (!this.$store.state.user || !this.$store.state.user.credits)
        return true;
      return this.$store.state.user.credits.credits === 0;
    },
  },
  methods: {
    fetchData(id) {
      api
        .getTheme(id)
        .then(({ data: theme }) => {
          this.theme = theme;
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    fetchSubmissionStatus() {
      api
        .getEssaySubmissionStatus()
        .then(({ data }) => {
          this.submissionAvailable = data;
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    handleDialog() {
      this.showDialog = !this.showDialog;
    },
    goToVideoPage(videoId) {
      this.$router.push({
        name: "VideoPage",
        params: { id: videoId },
      });
    }
  },
};
</script>

<style lang="sass" scoped>
.container
  min-height: 89vh
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.theme-content
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  max-width: 1300px
  width: 100%
  padding: 20px

.pdf-content
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  max-width: 1100px
  width: 100%

.content-title
  width: 100%
  display: flex
  justify-content: space-between
  align-items: flex-start
  flex-flow: wrap
  padding: 10px 10px
  &-description
    max-width: 600px
    width: 100%

.theme-image
  width: 100%
  border-radius: 15px
  max-height: 300px
  object-fit: cover

.content-image
  max-width: 400px

.pdf-buttons
  width: 100%
  margin: 10px
  display: flex
  justify-content: space-around
  align-items: center

.pdf-action-button
  width: 30%
  max-width: 250px

.pdf-submit-button
  width: 30%
  margin: 20px
  max-width: 250px

.credits-alert
  color: red
  font-size: 13px
</style>
