<template>
  <div class="container">
    <div class="content material-content">
      <div style="margin: 20px">
        <h2>{{ !isEdited ? 'Adicionar novo material' : 'Editar material' }}</h2>
      </div>
      <el-form
        ref="form"
        :model="material"
        :rules="rules"
        hide-required-asterisk
      >
        <el-form-item label="Título" prop="title">
          <el-input
            v-model="material.title"
            placeholder="Digite o título do material"
          ></el-input>
        </el-form-item>
        <el-form-item label="Descrição" prop="description">
          <el-input type="textarea" v-model="material.description"></el-input>
        </el-form-item>
        <el-form-item prop="file">
          <upload
            v-on:image="updateContent"
            type="pdf"
            label="Conteúdo (PDF)"
            url="pdf"
            :accepted="'.pdf'"
            :file="material.file"
          />
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" @click="validForm">{{ isEdited ? 'Salvar Alterações' : 'Adicionar' }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../services/api'
import notify from '../../traits/notify'
import errors from '../../traits/errors'
import Upload from '@/components/Upload'

export default {
  props: ['id'],
  components: {
    Upload,
  },
  data() {
    var validateFiles = (rule, value, callback) => {
      if (this.material.file === '') {
        callback(new Error('Adicione o arquivo de conteúdo'))
      } else if (this.loading) {
        callback(new Error('Aguarde os arquivos serem salvos no servidor!'))
      } else {
        callback()
      }
    }

    return {
      isEdited: this.id,
      loading: false,
      material: {
        title: '',
        description: '',
        file: '',
      },
      rules: {
        title: {
          required: true,
          message: 'Digite um título',
          trigger: 'blur',
        },
        description: {
          required: true,
          message: 'Digite uma descrição',
          trigger: 'blur',
        },
        file: {
          validator: validateFiles,
          trigger: 'blur',
        },
      },
    }
  },
  created() {
    if (this.isEdited) {
      this.fetchData(this.isEdited)
    }
  },
  methods: {
    fetchData(id) {
      api
        .getMaterial(id)
        .then(({ data: material }) => {
          this.material = material
        })
        .catch((err) => {
          errors.handleError(this, err)
        })
    },
    validForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) this.submit()
      })
    },
    submit() {
      if (this.isEdited) {
        api
          .updateMaterial(
            this.isEdited,
            this.material.title,
            this.material.description,
            this.material.file
          )
          .then(({ data }) => {
            notify.sucessNotification(
              this,
              `Material ${data.title} alterado com sucesso`,
              'A atualização já está disponível na lista de materiais'
            )
            this.resetForm()
            this.$router.replace(`/materiais`)
          })
          .catch((err) => {
            errors.handleError(this, err)
          })
      } else {
        api
          .createMaterial(
            this.material.title,
            this.material.description,
            this.material.file
          )
          .then(({ data }) => {
            notify.sucessNotification(
              this,
              `Material ${data.title} criado com sucesso`,
              'Novo material já pode ser accessado na lista de materiais'
            )
            this.resetForm()
            this.$router.replace(`/materiais`)
          })
          .catch((err) => {
            errors.handleError(this, err)
          })
      }
    },
    updateContent(data) {
      this.material.file = data
    },
    resetForm() {
      this.material.title = ''
      this.material.file = ''
      this.material.description = ''
    },
  },
}
</script>

<style lang="sass" scoped>
.container
  min-height: 89vh
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.material-content
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  max-width: 600px
  padding: 30px
</style>
