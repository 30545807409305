import RouterUtils from './utils'
import UserPage from '@/views/profile/UserPage'

export const userRoutes = [
  {
    path: '/perfil',
    name: 'UserProfile',
    component: UserPage,
    beforeEnter: (to, from, next) => {
      if (RouterUtils.checkUserActive()) {
        next()
      } else {
        next('/entrar')
      }
    },
  },
]
