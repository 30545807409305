<template>
  <div ref="content" id="parentx">
    <vs-sidebar
      :parent="$refs.navbar"
      default-index="1"
      color="primary"
      class="sidebarx"
      :recuce="true"
      spacer
      v-model="active"
    >
      <div class="header-sidebar" slot="header">
        <img width="120px" src="@/assets/logo_plataforma.png" />

        <h4 style="margin: 12px">{{ userName }}</h4>
        <h4>Créditos</h4>
        <h5 style="margin: 5px">Correção: {{ userCredits }} | Monitoria: {{ monitorCredits }}</h5>
        <span v-if="subscription" class="subscription-info">
          <strong>Atenção</strong>: O seu login e a validade dos seus créditos
          ficarão ativos até
          {{ subscription.finish_at | moment("DD/MM/YYYY") }}
        </span>
        <h6 style="margin-top: 5px">Versão: 1.6</h6>
      </div>

      <vs-sidebar-item :to="{ name: 'HomePage' }" index="1" icon="home"
        >Páginal Inicial</vs-sidebar-item
      >

      <vs-sidebar-item :to="{ name: 'Themes' }" index="1.1" icon="book"
        >Temas</vs-sidebar-item
      >

      <vs-sidebar-item
        index="1.2"
        icon="library_add"
        v-if="hasRole([roleAdmin])"
        :to="{ name: 'CreateTheme' }"
        >Adicionar Tema</vs-sidebar-item
      >

      <vs-sidebar-item :to="{ name: 'Essays' }" index="2" icon="menu_book">{{
        hasRole([roleAdmin, roleAdmin]) ? "Redações" : "Minhas Redações"
      }}</vs-sidebar-item>

      <vs-sidebar-item
        :to="{ name: 'EssayWithoutTheme' }"
        index="3"
        icon="post_add"
        >Redação - Tema Livre</vs-sidebar-item
      >

      <vs-sidebar-item
        :to="{ name: 'Materials' }"
        v-if="true"
        index="4"
        icon="move_to_inbox"
        >Materiais para estudo</vs-sidebar-item
      >

      <vs-sidebar-item
        index="4.1"
        icon="note_add"
        v-if="hasRole([roleAdmin])"
        :to="{ name: 'CreateMaterial' }"
        >Adicionar Material</vs-sidebar-item
      >

      <vs-sidebar-item v-if="false" index="5" icon="trending_up"
        >Meu Desempenho</vs-sidebar-item
      >

      <vs-divider icon="ondemand_video" position="left" />

      <vs-sidebar-item
        index="8"
        icon="video_library"
        :to="{ name: 'ListPlaylists' }"
        >Módulos</vs-sidebar-item
      >

      <vs-sidebar-item
        index="8.1"
        icon="subscriptions"
        :to="{ name: 'ListVideos' }"
        >Todas as Aulas</vs-sidebar-item
      >

      <vs-sidebar-item
        v-if="hasRole([roleAdmin])"
        index="8.2"
        icon="playlist_add"
        :to="{ name: 'CreatePlaylist' }"
        >Adicionar Módulo</vs-sidebar-item
      >

      <vs-sidebar-item
        v-if="hasRole([roleAdmin])"
        index="8.3"
        icon="video_call"
        :to="{ name: 'CreateVideo' }"
        >Adicionar Vídeo</vs-sidebar-item
      >

      <vs-divider icon="person" position="left" />

      <vs-sidebar-item
        v-if="hasRole([roleAdmin])"
        index="9"
        icon="verified_user"
        :to="{ name: 'Users' }"
        >Usuários</vs-sidebar-item
      >
      <vs-sidebar-item
        v-if="hasRole([roleAdmin])"
        index="5"
        icon="settings"
        :to="{ name: 'Config' }"
        >Configurações</vs-sidebar-item
      >
      <vs-sidebar-item
        v-if="hasRole([roleAdmin])"
        index="6"
        icon="view_list"
        :to="{ name: 'CreditsReport' }"
        >Relatório de Créditos</vs-sidebar-item
      >
      <vs-sidebar-item
        v-if="hasPermission([logEssayPermission])"
        index="7"
        icon="list_alt"
        :to="{ name: 'EssaysReport' }"
        >Relatório de Redações</vs-sidebar-item
      >
      <vs-sidebar-item
        v-if="true"
        index="15"
        icon="account_box"
        :to="{ name: 'UserProfile' }"
      >
        Perfil
      </vs-sidebar-item>

      <div slot="footer">
        <vs-button
          class="logout-button"
          icon="reply"
          color="danger"
          type="flat"
          @click="logout"
          >Sair</vs-button
        >
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import authorization from "@/traits/authorization";

export default {
  name: "Sidebar",
  props: ["show"],
  data: () => ({
    active: false,
  }),
  watch: {
    show: function () {
      this.active = !this.active;
    },
  },
  computed: {
    userName() {
      return this.$store.state.user.name;
    },
    roleAdmin() {
      return authorization.ROLE_ADMIN;
    },
    roleStudent() {
      return authorization.ROLE_STUDENT;
    },
    roleProfessor() {
      return authorization.ROLE_PROFESSOR;
    },
    logEssayPermission() {
      return authorization.PERMISSION_LOGS_ESSAY_VIEW;
    },
    userCredits() {
      if (!this.$store.state.user || !this.$store.state.user.credits) return 0;
      return this.$store.state.user.credits.credits;
    },
    monitorCredits() {
      if (!this.$store.state.user || !this.$store.state.user.credits) return 0;
      return this.$store.state.user.credits.monitor_credits;
    },
    subscription() {
      if (!this.$store.state.user || !this.$store.state.user.subscriptions)
        return null;
      return this.$store.state.user.subscriptions[0];
    },
  },
  methods: {
    logout() {
      this.active = false;
      this.$store.dispatch("logout");
    },
    hasRole(roles) {
      return authorization.hasRole(roles);
    },
    hasPermission(permissions) {
      return authorization.hasPermission(permissions);
    },
  },
};
</script>

<style lang="sass">
#parentx
  overflow: hidden
  height: 100%
  display: table-row

.header-sidebar
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  width: 100%

.logout-button
  width: 100% !important

.vs-sidebar--item
  margin: 10px

.subscription-info
  text-align: center
  padding: 10px 10px
  font-size: 0.75em
</style>
