<template>
  <div id="app">
    <div v-if="loaded" class="app-content">
      <Navbar />
      <router-view />
      <div class="footer" />
    </div>
    <div
      v-else
      style="
      height: 100vh;
      width: 100%;
      background-image: linear-gradient(rgb(32, 142, 220), rgb(32, 142, 220));
      justify-content: center;
      display: flex;
      align-items: center;"
    >
      <img width="200" src="/loading.gif" alt="" />
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar';
import store from '@/store/index';

export default {
  name: 'app',
  components: {
    Navbar
  },
  data() {
    return {
      loaded: false
    };
  },
  async created() {
    try {
      document.title = 'Correção On-line';
      this.loaded = false;
      await store.dispatch('fetchUser');
    } catch (e) {
      console.log(e)
    } finally {
      this.loaded = true;
    }
  }
};
</script>

<style lang="sass">
*
  margin: 0
  padding: 0
  box-sizing: border-box

html, body
  height: 100%

#app
  background-image: linear-gradient(135deg, rgb(32, 142, 220), rgb(107, 65, 227))
  font-family: Montserrat, Roboto, sans-serif
  width: 100%
  min-height: 100vh

.content
  padding: 20px 20px
  background-color: white
  border-radius: 20px
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.2)
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.2)
  margin: 20px

.app-content
  min-height: 89vh

.my-notification
  margin-top: 60px
  top: 60px

.footer
  width: 100%
  height: 5vh

.el-form
  width: 100%
  max-width: 700px

.el-dialog
  min-width: 340px

.el-upload
  border: 1px dashed #d9d9d9
  border-radius: 6px
  cursor: pointer
  position: relative
  overflow: hidden

.el-upload:hover
  border-color: #409EFF
</style>
