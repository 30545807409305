<template>
  <div class="container">
    <div class="content users-content">
      <UserForm
        v-if="showDialog"
        v-on:updateDialog="handleDialog"
        :user-id="userId"
        :show-dialog.sync="showDialog"
      />
      <CreditsForm
        v-on:updateDialog="handleDialogCredits"
        :user-id="userId"
        :show-dialog="showDialogCredits"
      />
      <SubscriptionForm
        v-if="showDialogSubscription"
        v-on:updateDialog="handleDialogSubscription"
        :user="user"
        :show-dialog="showDialogSubscription"
      />
      <h2 style="margin: 10px 10px">Usuários</h2>
      <div class="filter-container">
        <el-input
          v-model="query.keyword"
          placeholder="Buscar"
          style="width: 200px; margin: 10px 10px"
          @keyup.enter.native="handleFilter"
        />
        <el-select
          v-model="query.active"
          placeholder="Status"
          clearable
          style="width: 120px; margin: 10px 10px"
          @change="handleFilter"
        >
          <el-option
            v-for="status in statusArray"
            :key="status.key"
            :label="status.value"
            :value="status.key"
          />
        </el-select>
        <el-select
          v-model="query.role"
          placeholder="Perfil"
          clearable
          style="width: 120px; margin: 10px 10px"
          @change="handleFilter"
        >
          <el-option
            v-for="role in roleArray"
            :key="role.key"
            :label="role.value"
            :value="role.key"
          />
        </el-select>
        <el-button
          type="primary"
          icon="el-icon-search"
          style="width: 120px; margin: 10px 10px"
          @click="handleFilter"
          >Buscar</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-plus"
          style="margin: 10px 10px"
          @click="handleCreateUser"
          >Adicionar Usuário</el-button
        >
      </div>

      <el-table
        v-loading="loading"
        :data="users"
        fit
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column align="center" label="Nome" width="180">
          <template slot-scope="scope">
            <span
              @click="() => handleClickedRow(scope.row)"
              style="cursor: pointer"
            >
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>

        <el-table-column align="center" label="Email" width="320">
          <template slot-scope="scope">
            <span>{{ scope.row.email }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" label="Perfil" width="120">
          <template slot-scope="scope">
            <span>{{ handleRoles(scope.row.roles) }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" label="Créditos" width="80">
          <template slot-scope="scope">
            <span>{{ handleCredits(scope.row.credits) }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" label="Status" width="80">
          <template slot-scope="scope">
            <span>{{
              scope.row.active ? status.active : status.inactive
            }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" label="Valido até" width="120">
          <template slot-scope="scope">
            <span>{{
              handleSubscriptions(scope.row.subscriptions)
                | moment("DD/MM/YYYY")
            }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" label="Ações" width="270">
          <template slot-scope="scope">
            <div class="table-buttons">
              <el-button
                icon="el-icon-edit"
                size="mini"
                type="primary"
                @click="handleEditUser(scope.row.id)"
              />
              <el-button
                icon="el-icon-date"
                size="mini"
                type="primary"
                @click="handleEditSubscription(scope.row)"
                :disabled="handleDisableNotStudent(scope.row.roles)"
                plain
              />
              <el-button
                icon="el-icon-plus"
                size="mini"
                type="success"
                :disabled="handleDisableNotStudent(scope.row.roles)"
                @click="handleAddCredits(scope.row)"
                >Créditos</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="pager"
        :total="query.total"
        :page-size="query.limit"
        :limit.sync="query.limit"
        :current-page="query.page"
        :pager-count="5"
        @current-change="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import api from "../../services/api";
import errors from "../../traits/errors";
import authorization from "../../traits/authorization";
import UserForm from "./components/UserForm";
import CreditsForm from "./components/CreditsForm";
import SubscriptionForm from "./components/SubscriptionForm";

export default {
  name: "Users",
  components: {
    UserForm,
    CreditsForm,
    SubscriptionForm,
  },
  data() {
    return {
      users: [],
      userId: null,
      user: null,
      loading: false,
      showDialog: false,
      showDialogCredits: false,
      showDialogSubscription: false,
      query: {
        total: 0,
        page: 1,
        limit: 10,
        keyword: "",
        active: "",
        role: "",
      },
      status: {
        active: "Ativo",
        inactive: "Inativo",
      },
      statusArray: [
        {
          key: true,
          value: "Ativo",
        },
        {
          key: false,
          value: "Inativo",
        },
      ],
      roles: {
        [authorization.ROLE_ADMIN]: "Administrador",
        [authorization.ROLE_PROFESSOR]: "Professor",
        [authorization.ROLE_STUDENT]: "Aluno",
      },
      roleArray: [
        {
          key: authorization.ROLE_ADMIN,
          value: "Administrador",
        },
        {
          key: authorization.ROLE_PROFESSOR,
          value: "Professor",
        },
        {
          key: authorization.ROLE_STUDENT,
          value: "Aluno",
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleFilter() {
      this.query.page = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      api
        .getUsers(this.query)
        .then(({ data }) => {
          const { data: users } = data;
          this.query.total = Number(data.total);
          this.users = users;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          errors.handleError(this, err);
        });
    },
    handleRoles(rolesArray) {
      var rolesString = "";
      rolesArray.forEach((element) => {
        rolesString += ` - ${this.roles[element.slug]}`;
      });
      return rolesString;
    },
    cancelAlert() {
      this.$vs.notify({
        color: "success",
        title: "Operação cancelada",
      });
    },
    handleCredits(credits) {
      if (!credits) return "-";
      return credits.credits;
    },
    handleSubscriptions(subscriptions) {
      if (!subscriptions[0]) return "-";
      return subscriptions[0].finish_at;
    },
    handleChangePage(page) {
      this.query.page = page;
      this.getList();
    },
    handleDialog(value) {
      this.showDialog = value;
      this.getList();
    },
    handleDialogCredits(value) {
      this.showDialogCredits = value;
      this.getList();
    },
    handleDialogSubscription(value) {
      this.showDialogSubscription = value;
      this.getList();
    },
    handleCreateUser() {
      this.showDialog = true;
      this.userId = null;
    },
    handleEditUser(userId) {
      this.showDialog = true;
      this.userId = userId;
    },
    handleEditSubscription(user) {
      this.showDialogSubscription = true;
      this.user = user;
    },
    handleAddCredits(user) {
      this.showDialogCredits = true;
      this.userId = user.id;
    },
    handleDisableNotStudent(roles) {
      return !(roles[0].slug === authorization.ROLE_STUDENT);
    },
    handleClickedRow(row) {
      this.$router.push({
        name: "UserPage",
        params: { id: row.id },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start

.users-content
  width: 90%
  margin: 20px
  max-width: 1300px
  margin-top: 80px

.table-buttons
  display: flex
  justify-content: space-around

.status-component
  color: white
  padding: 5px
  border-radius: 7px 7px

  &-Pending
    @extend .status-component
    background-color: #409EFF !important

  &-Revised
    @extend .status-component
    background-color: #6de42b !important

  &-Correcting
    @extend .status-component
    background-color: #ffb300 !important

.primary-row
  color: #5b3cc4

.success-row
  color: #2e8200

.warning-row
  color: #9c6e00
</style>
