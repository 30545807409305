<template>
  <div v-if="alert.status" class="content alert-content">
    <div v-loading="loading" class="banner-content">
      <div class="banner-message">
        <div class="banner-title">
          <img
            height="30px"
            width="30px"
            src="@/assets/warning.png"
            alt="Alerta"
          />
          <div style="margin-left: 10px">{{ alert.title }}</div>
        </div>
        <div>
          {{ alert.message }}
        </div>
        <div v-if="hasLink" class="banner-link">
          <strong>{{ alert.linkTitle }}</strong>
          <a :href="alert.link" style="word-break: break-word;" target="_blank">
            {{ alert.link }}
          </a>
        </div>
        <div class="banner-subject">
          <img
            height="30px"
            width="30px"
            src="@/assets/logo_plataforma.png"
            alt="Alerta"
          />
          <div style="margin-left: 10px; text-align: center">
            Correção de qualidade é para todos!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import notify from "@/traits/notify";

export default {
  name: "Banner",
  data() {
    return {
      loading: false,
      alert: {
        status: false,
        title: "",
        message: "",
        link: "",
        linkTitle: "",
      },
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    hasLink: function () {
      return this.alert.link && this.alert.linkTitle;
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      api
        .getConfig()
        .then(({ data }) => {
          this.alert = data.alert;
        })
        .catch(() => {
          notify.errorNotification(
            this,
            `Ocorreu algum erro ao carregar o banner`,
            "Tente atualizar a página para resolver"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.banner-content
  border-radius: 10px
  padding: 20px
  background-image: url('~@/assets/enem.jpg')
  background-position: center
  background-size: cover

.banner-message
  display: flex
  justify-content: center
  align-items: center
  padding: 15px
  flex-direction: column
  background-color: white
  text-align: justify
  border-radius: 10px

.banner-title
  font-size: 20px
  display: flex
  align-items: center
  font-weight: bold
  margin-bottom: 10px

.banner-subject
  display: flex
  justify-content: center
  align-items: center
  margin-top: 10px
  font-size: 16px
  font-weight: bold
  font-style: italic

.alert-content
  display: flex
  justify-content: center
  align-items: center
  flex-wrap: wrap
  max-width: 1000px
  padding: 10px
  margin: 20px

.banner-link
  margin: 20px 0px 10px
  text-align: center
</style>
