import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Vuesax from 'vuesax';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/pt-br';
import 'element-ui/lib/theme-chalk/index.css';
import 'vuesax/dist/vuesax.css';
import 'material-icons/iconfont/material-icons.css';
import Notifications from 'vue-notification';
import VueApexCharts from 'vue-apexcharts';
import VueMoment from 'vue-moment';
import infiniteScroll from 'vue-infinite-scroll';
import VueGtag from 'vue-gtag';
import vueVimeoPlayer from 'vue-vimeo-player';
import { VueMaskDirective } from 'v-mask';
import NwImg from 'nw-img-vue';

Vue.use(NwImg);
Vue.directive('mask', VueMaskDirective);
Vue.use(infiniteScroll);
Vue.use(VueMoment);
Vue.use(VueApexCharts);
Vue.use(VueGtag, {
  config: {
    id: 'UA-177102381-1',
    params: {
      send_page_view: true
    }
  }
});
Vue.use(vueVimeoPlayer);
Vue.component('apexchart', VueApexCharts);
Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
Vue.use(Notifications);
Vue.use(Vuesax, {
  theme: {
    colors: {
      primary: 'rgb(0,0,0)',
      success: 'rgb(23, 201, 100)',
      danger: 'rgb(249,65,68)',
      warning: 'rgb(255, 130, 0)',
      dark: 'rgb(36, 33, 69)'
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
