<template>
  <div class="container">
    <div v-loading="loading" class="content class-content" id="correction-page">
      <el-row>
        <p class="header-title">
          {{ themeTitle }}
        </p>
      </el-row>
      <el-row align="middle" type="flex" justify="center">
        <el-col :xs="0" :sm="4" :md="3" :lg="3" :xl="3">
          <img src="@/assets/logo_plataforma.png" class="header-logo" />
        </el-col>
        <el-col :xs="24" :sm="20" :md="21" :lg="21" :xl="21">
          <div class="header-colors">
            <div>
              <ColorLabel
                v-for="item in colors_1"
                :key="item.color"
                :color="item.color"
                :description="item.text"
              />
            </div>
            <div>
              <ColorLabel
                v-for="item in colors_2"
                :key="item.color"
                :color="item.color"
                :description="item.text"
              />
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <p class="header-student-title">Aluno: {{ essay.user.name }}</p>
      </el-row>
      <el-row>
        <PDFViewer
          v-if="essay.correction"
          :url="essay.correction"
          :downloadAllowed="true"
          downloadPosition="bottom"
        />
      </el-row>
      <div>
        <div style="padding: 1px">
          <CommentBlock
            :title="competence_1.title"
            :grade="competence_1.grade"
            :description="competence_1.description"
          />
        </div>
        <div style="padding: 1px">
          <CommentBlock
            :title="competence_2.title"
            :grade="competence_2.grade"
            :description="competence_2.description"
          />
        </div>
        <div style="padding: 1px">
          <CommentBlock
            :title="competence_3.title"
            :grade="competence_3.grade"
            :description="competence_3.description"
          />
        </div>
        <div style="padding: 1px">
          <CommentBlock
            :title="competence_4.title"
            :grade="competence_4.grade"
            :description="competence_4.description"
          />
        </div>
        <div style="padding: 1px">
          <CommentBlock
            :title="competence_5.title"
            :grade="competence_5.grade"
            :description="competence_5.description"
          />
        </div>
      </div>
      <div style="padding-top: 10px">
        <FinalGrade :grade="finalGrade" />
      </div>
      <el-row type="flex" justify="end" style="margin: 10px">
        <el-button
          @click="handleDownload"
          type="primary"
          class="download-button"
        >
          Baixar Correção
        </el-button>
        <el-button
          @click="handleAudioDownload"
          type="primary"
          class="download-button"
        >
          Baixar Aúdio
        </el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import ColorLabel from "./components/ColorLabel.vue";
import CommentBlock from "./components/CommentBlock.vue";
import PDFViewer from "@/components/PDFViewer.vue";
import FinalGrade from "./components/FinalGrade.vue";
import domtoimage from "dom-to-image";
import api from "@/services/api";
import errors from "@/traits/errors";

export default {
  name: "CorrectionPage",
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  components: { ColorLabel, CommentBlock, PDFViewer, FinalGrade },
  data() {
    return {
      loading: false,
      essay: {
        theme: {
          title: "",
        },
        user: {
          name: "",
        },
      },
      competence_1: {
        title:
          "Competência 1: Demonstrar domínio da norma padrão da língua portuguesa.",
        grade: 0,
        description: "",
      },
      competence_2: {
        title:
          "Competência 2: Compreender a proposta de redação e aplicar conceitos das várias áreas do conhecimento para desenvolver o tema, dentro dos limites estruturais do texto dissertativo-argumentativo.",
        grade: 0,
        description: "",
      },
      competence_3: {
        title:
          "Competência 3: Selecionar, relacionar, organizar e interpretar as informações, fatos, opiniões e argumentos em defesa de um ponto de vista.",
        grade: 0,
        description: "",
      },
      competence_4: {
        title:
          "Competência 4: Demonstrar conhecimento dos mecanismos linguísticos necessários para a construção da argumentação.",
        grade: 0,
        description: "",
      },
      competence_5: {
        title:
          "Competência 5: Elaborar proposta de intervenção coerente com o que foi discutido, completa com os elementos essenciais (agente, ação, modo, efeito e detalhamento) e que respeite os Direitos Humanos.",
        grade: 0,
        description: "",
      },
      colors_1: [
        {
          color: "red",
          text: "Desvios gramaticais",
        },
        {
          color: "#00b0f0",
          text: "Indicação de repertório sociocultural",
        },
        {
          color: "#70ad47",
          text: "Tese/tópico frasal",
        },
      ],
      colors_2: [
        {
          color: "brown",
          text: "Inadequação coesa/repetição/falta de conectivo",
        },
        {
          color: "black",
          text: "Informação vaga/generalizada/falta de informação",
        },
        {
          color: "#A0F",
          text: "Elementos da proposta de intervenção",
        },
      ],
    };
  },
  created() {
    this.getEssay(this.id);
  },
  watch: {
    id: function () {
      this.getEssay(this.id);
    },
  },
  computed: {
    finalGrade: function () {
      return (
        this.competence_1.grade +
        this.competence_2.grade +
        this.competence_3.grade +
        this.competence_4.grade +
        this.competence_5.grade
      );
    },
    themeTitle: function () {
      if (this.essay.theme) {
        return this.essay.theme.title;
      }

      return this.essay.material_title;
    },
  },
  methods: {
    getEssay(id) {
      api
        .getEssay(id)
        .then(({ data: essay }) => {
          this.essay = essay;
          this.competence_1.grade = essay.competence_1;
          this.competence_1.description = essay.competence_1_description;
          this.competence_2.grade = essay.competence_2;
          this.competence_2.description = essay.competence_2_description;
          this.competence_3.grade = essay.competence_3;
          this.competence_3.description = essay.competence_3_description;
          this.competence_4.grade = essay.competence_4;
          this.competence_4.description = essay.competence_4_description;
          this.competence_5.grade = essay.competence_5;
          this.competence_5.description = essay.competence_5_description;
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    handleDownload() {
      var node = document.getElementById("correction-page");
      var slug = this.themeTitle.toLowerCase().trim().replaceAll(" ", "-") ||
        "my-image-name";
      domtoimage
        .toPng(node)
        .then(function (dataUrl) {
          let link = document.createElement("a");
          link.download = `${slug}.png`;
          link.href = dataUrl;
          link.click();
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },
    handleAudioDownload() {
      window.open(this.essay.audio, "_blank");
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  padding-top: 80px

.class-content
  width: 95%
  margin: 0px
  padding: 10px
  max-width: 1200px

.header-logo
  width: 100%
  object-fit: cover
  margin: 5px
  padding-right: 15px

.header-title
  text-align: center
  font-size: 30px
  font-family: 'Concert One'
  font-weight: 500
  margin: 10px

.header-student-title
  text-align: center
  font-size: 26px
  font-family: 'Concert One'
  font-weight: 500
  margin-bottom: 15px

.header-colors
  margin: 20px
  display: flex
  justify-content: space-between
  flex-flow: wrap

.download-button
  background: rgb(107, 65, 227)
  border-color: rgb(107, 65, 227)
  color: white
</style>
