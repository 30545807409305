<template>
  <div>
    <ConfirmationDialog
      :showDialog="showDeleteDialog"
      :onDelete="onDelete"
      @updateDialog="() => setDeleteDialog(false)"
    />
    <el-tooltip effect="dark" content="Já assistido" placement="top">
      <div v-if="isChecked" class="button-options check-class" type="success">
        <i class="el-icon-check"></i>
      </div>
    </el-tooltip>
    <div v-if="hasRole([roleAdmin])" class="button-options">
      <vs-button
        color="primary"
        type="gradient"
        @click="() => onUpdate(video.id)"
      >
        Editar
      </vs-button>
      <vs-button
        color="danger"
        type="gradient"
        @click="() => setDeleteDialog(true)"
      >
        Excluir
      </vs-button>
    </div>
    <div @click="() => onPressed(video.id)" style="padding: 25px 10px">
      <el-row class="video-card">
        <el-col :span="24">
          <div class="image-container">
            <NwImg
              :src="video.image"
              alt="Imagem do Módulo"
              class="image-video"
            />
            <p v-if="playlist" class="image-bottom-right">
              {{ playlist.title }}
            </p>
          </div>
        </el-col>
        <el-col :span="24" class="video-details">
          <el-row>
            <h3>{{ video.title }}</h3>
          </el-row>
          <br />
          <el-row>
            <p style="font-size: 15px">
              {{ video.description }}
            </p>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import authorization from "@/traits/authorization";
import api from "@/services/api";
import notify from "@/traits/notify";
import errors from "@/traits/errors";
import ConfirmationDialog from "@/components/ConfirmationDialog";

export default {
  name: "VideoCardMini",
  props: {
    video: {
      type: Object,
      required: true,
    },
    isChecked: {
      required: false,
      type: Boolean,
      default: false,
    },
    playlist: {
      required: false,
      type: Object,
    },
  },
  components: { ConfirmationDialog },
  data() {
    return {
      showDeleteDialog: false,
    };
  },
  computed: {
    roleAdmin() {
      return authorization.ROLE_ADMIN;
    },
  },
  methods: {
    onPressed(videoId) {
      this.$router.push({
        name: "VideoPage",
        params: { id: videoId },
      });
    },
    hasRole(roles) {
      return authorization.hasRole(roles);
    },
    onUpdate(videoId) {
      this.$router.push({
        name: "UpdateVideo",
        params: { id: videoId },
      });
    },
    setDeleteDialog(value) {
      this.showDeleteDialog = value;
    },
    onDelete() {
      api
        .deleteVideo(this.video.id)
        .then(() => {
          this.$emit("updateList", true);
          notify.sucessNotification(
            this,
            "Video excluído com sucesso",
            "Modulo inacessível para usuários"
          );
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.image-video
  width: 100%
  border-radius: 10px
  margin-right: 15px
  height: 150px
  object-fit: cover

.image-container
  position: relative
  text-align: center
  color: white

.image-bottom-right
  position: absolute
  bottom: 4px
  right: 0px
  background-color: #2d2d2ddd
  padding: 2px 6px
  border-radius: 5px

.video-card
  min-height: 150px
  max-width: 350px
  min-width: 200px
  padding: 5px
  margin: 5px
  border-radius: 10px
  background-color: white
  color: #4C5150
  -webkit-box-shadow: 0px 5px 40px -20px rgba(0,0,0,0.5)
  -moz-box-shadow: 0px 5px 40px -20px rgba(0,0,0,0.5)
  box-shadow: 0px 5px 40px -20px rgba(0,0,0,0.5)

  &:hover
    transition: all 0.5s
    cursor: pointer
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.5)
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.5)
    box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.5)

.video-details
  padding: 5px 15px

.check-class
  padding: 5px
  height: 40px
  width: 40px
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  color: white
  background-color: #6de42b
  border-radius: 50%
  border: 3px solid white

.button-options
  position: relative
  top: 10px
  z-index: 100
  float: right
</style>
