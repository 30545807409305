<template>
  <div class="container">
    <div class="content register-content">
      <img width="200px" src="@/assets/logo_plataforma.png" />
      <h2>Cadastro</h2>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        hide-required-asterisk
        label-position="top"
      >
        <el-form-item label="Nome" prop="name">
          <el-input
            v-model="form.name"
            placeholder="Digite seu nome"
          ></el-input>
        </el-form-item>
        <el-form-item label="E-mail" prop="email" hide-required-asterisk>
          <el-input
            v-model="form.email"
            placeholder="Digite seu e-mail"
          ></el-input>
        </el-form-item>
        <el-form-item label="Telefone" prop="phone">
          <el-input
            v-mask="'(##) #####-####'"
            v-model="form.phone"
            placeholder="Digite seu número de telefone"
          ></el-input>
        </el-form-item>
        <el-form-item label="Estado" prop="state">
          <el-select
            v-model="form.state"
            filterable
            placeholder="Selecione seu estado"
            clearable
            style="display: flex"
            autocomplete="off"
            value-key="codigo_uf"
          >
            <el-option
              v-for="item in states"
              :key="item.codigo_uf"
              :label="item.nome"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.state" label="Cidade" prop="city">
          <el-select
            v-model="form.city"
            filterable
            placeholder="Selecione sua cidade"
            clearable
            style="display: flex"
            value-key="codigo_ibge"
          >
            <el-option
              v-for="item in filteredCities"
              :key="item.codigo_ibge"
              :label="item.nome"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Senha" prop="password" hide-required-asterisk>
          <el-input
            v-model="form.password"
            type="password"
            placeholder="Digite sua senha"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Confirme sua senha"
          prop="checkPass"
          hide-required-asterisk
        >
          <el-input
            v-model="form.checkPass"
            type="password"
            placeholder="Confirme sua senha"
            @keyup.enter.native="submit"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submit">Cadastrar</el-button>
      <div class="form-item">
        <span> Já possui uma conta? </span>
        <router-link to="/entrar">Entre</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../services/api";
import notify from "../../traits/notify";
import { mapMutations } from "vuex";
import estados from "@/assets/estados.json";
import municipios from "@/assets/municipios.json";

export default {
  name: "Register",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Digite sua senha novamente"));
      } else if (value !== this.form.password) {
        callback(new Error("As duas senhas não são iguais"));
      } else {
        callback();
      }
    };

    return {
      states: estados,
      cities: municipios,
      form: {
        name: "",
        email: "",
        phone: "",
        state: null,
        city: null,
        password: "",
        checkPass: "",
      },
      rules: {
        name: {
          required: true,
          message: "Digite seu nome",
          trigger: "blur",
        },
        email: [
          {
            required: true,
            message: "Digite um endereço de e-mail",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor, digite um e-mail válido",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Digite seu número de telefone",
            trigger: 'blur',
            pattern: /^\(\d{2}\) \d{5}-\d{4}$/,
          },
        ],
        state: {
          required: true,
          message: "Digite seu Estado",
          trigger: "blur",
        },
        city: {
          required: true,
          message: "Digite sua cidade",
          trigger: "blur",
        },
        password: [
          {
            required: true,
            message: "Digite uma senha",
            trigger: "blur",
          },
          {
            min: 6,
            max: 20,
            message: "Senha deve ter entre 6 e 20 carácteres",
            trigger: "blur",
          },
        ],
        checkPass: {
          validator: validatePass,
          trigger: "blur",
        },
      },
    };
  },
  computed: {
    filteredCities() {
      console.log(this.form);
      if (!this.form.state) return [];
      return this.cities.filter(
        (element) => element.codigo_uf === this.form.state.codigo_uf
      );
    },
  },
  methods: {
    ...mapMutations([
      "updateAccessToken",
      "updateUser",
      "updateRoles",
      "updatePermissions",
    ]),
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) this.signup();
      });
    },
    signup() {
      const phone = this.form.phone.replace(/\D/g, '');
      const state = this.form.state.nome;
      const city = this.form.city.nome;
      api
        .register(
          this.form.name,
          this.form.email,
          this.form.password,
          phone,
          state,
          city
        )
        .then(() => {
          notify.sucessNotification(
            this,
            "Cadastro Realizado",
            "Aguarde a liberação do acesso para utilizar a plataforma"
          );
          this.$router.replace("/entrar");
        })
        .catch((e) => {
          if (e.response.status === 409) {
            notify.errorNotification(
              this,
              "E-mail já cadastrado",
              "Se você já tem uma conta, faça login com ela"
            );
          } else {
            notify.errorNotification(
              this,
              "Ocorreu Algum Erro",
              "Verifique seus dados"
            );
          }
        });
    },
    viewtoken() {
      console.log(this.$store.state.token);
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  width: 100%
  min-height: 89vh
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.register-content
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 90%
  max-width: 450px
  padding: 40px 40px

.form-item
  margin-top: 10px

.login-button
  margin-top: 20px
  width: 120px
</style>
