import RouterUtils from './utils'
import Users from '@/views/users/Users'
import UserPage from '@/views/profile/UserPage'
import Config from '@/views/config/Config'
import Credits from '@/views/reports/Credits'
import auth from '@/traits/authorization'

export const adminRoutes = [
  {
    path: '/usuarios',
    name: 'Users',
    component: Users,
    beforeEnter: (to, from, next) => {
      if (RouterUtils.checkUserActive()) {
        RouterUtils.checkUserHasPermission([
          auth.PERMISSION_USERS_VIEW
        ]) ? next() : next('/')
      } else {
        next('/entrar')
      }
    },
  },
  {
    path: '/usuarios/:id',
    name: 'UserPage',
    component: UserPage,
    props: true,
    beforeEnter: (to, from, next) => {
      if (RouterUtils.checkUserActive()) {
        RouterUtils.checkUserHasPermission([
          auth.PERMISSION_THEME_MADE_BY_USER_VIEW,
        ]) ? next() : next('/')
      } else {
        next('/entrar')
      }
    },
  },
  {
    path: '/configuracao',
    name: 'Config',
    component: Config,
    beforeEnter: (to, from, next) => {
      if (RouterUtils.checkUserActive()) {
        RouterUtils.checkUserIsAdmin() ? next() : next('/')
      } else {
        next('/entrar')
      }
    },
  },
  {
    path: '/relatorio/creditos',
    name: 'CreditsReport',
    component: Credits,
    beforeEnter: (to, from, next) => {
      if (RouterUtils.checkUserActive()) {
        RouterUtils.checkUserIsAdmin() ? next() : next('/')
      } else {
        next('/entrar')
      }
    },
  },
]
