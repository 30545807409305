<template>
  <div>
    <apexchart type="donut" :options="options" :series="series" />
  </div>
</template>

<script>
import pt_br from "apexcharts/dist/locales/pt-br.json";

export default {
  name: "CompetenceDonutChart",
  props: {
    title: {
      required: true,
      type: String,
    },
    data: {
      required: true,
      type: Array,
      default: () => [],
    },
    max: {
      required: false,
      default: 200,
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed: {
    options: function () {
      return {
        colors: ["#2E93fA", "#fb5607"],
        title: {
          text: this.title,
          align: "center",
        },
        chart: {
          id: "shipments",
          locales: [pt_br],
          defaultLocale: "pt-br",
        },
        labels: ["Pontuação média", "Pontos não atingidos"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: this.makeTotalStyle(),
              },
            },
          },
        },
      };
    },
    seriesTotal: function () {
      if (!this.data.length) return 0;
      return this.data.reduce((l, r) => l + r);
    },
    seriesMean: function () {
      if (!this.data.length) return 0;
      return Math.round(this.seriesTotal / this.data.length);
    },
    xMean: function () {
      const length = this.data.length;
      return (length * (length + 1)) / 2;
    },
    regressionCoefficient: function () {
      if (!this.data.length) return 0;
      const numerator = this.data
        .map((e, index) => (index - this.xMean) * (e - this.seriesMean))
        .reduce((l, r) => l + r);
      const denominator = this.data
        .map((e, index) => Math.pow(index - this.xMean, 2))
        .reduce((l, r) => l + r);
      return numerator / denominator;
    },
    series: function () {
      if (!this.data.length) return [1];
      return [this.seriesMean, this.max - this.seriesMean];
    },
    emoji: function () {
      if (this.regressionCoefficient > 0) return "↗️";
      if (this.regressionCoefficient < 0) return "↘️";
      return "➡️";
    },
  },
  methods: {
    makeTotalStyle() {
      return {
        show: true,
        label: "Domínio",
        color: this.regressionCoefficient < 0 ? "#FB5607" : "#2E93fA",
        formatter: () => {
          if (!this.data.length) return "Sem Dados";
          return (
            `${this.emoji} ` +
            (Math.round((this.seriesMean / this.max) * 100) + "%")
          );
        },
      };
    },
  },
};
</script>
