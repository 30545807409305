<template>
  <div class="my-upload-component">
    <span>{{ label }}</span>
    <div style="margin: 10px; max-width: 200px">
      <el-upload
        :limit="1"
        :action="uploadURL"
        :headers="headers"
        :name="type"
        :accept="accepted"
        :on-success="handleSuccess"
        :before-upload="beforeUpload"
        :on-error="handleError"
        :on-remove="onDelete"
        :file-list="fileList"
        list-type="picture"
      >
        <i class="el-icon-plus avatar-uploader-icon"></i>
        <strong v-if="loading">Enviando...</strong>
      </el-upload>
    </div>
  </div>
</template>

<script>
import notify from "../traits/notify";
import api from "@/services/api";

export default {
  props: ["type", "label", "url", "accepted", "file"],
  data() {
    return {
      text: "Envio de arquivo",
      fileList: [],
      fileUrl: "",
      loading: false,
      uploadURL: process.env.VUE_APP_API_BASE_URL + "/upload/" + this.url,
      headers: {
        Authorization: "Bearer " + this.$store.state.accessToken,
      },
    };
  },
  created() {
    if (this.file) this.fileList = [{ name: this.label, url: this.file }];
  },
  watch: {
    file: function (val) {
      if (val) this.fileList = [{ name: this.label, url: val }];
    },
  },
  methods: {
    handleSuccess(res, file) {
      this.fileUrl = URL.createObjectURL(file.raw);
      this.loading = false;
      notify.sucessNotification(
        this,
        "Arquivo adicionado com sucesso",
        "Novo arquivo adicionado"
      );
      this.$emit("image", res);
    },
    beforeUpload(file) {
      if (file.size / 1024 / 1024 > 5) {
        notify.errorNotification(
          this,
          "O Arquivo deve ter menor que 5MB",
          "Reduza o Tamanho do arquivo e tente novamente"
        );
        return false;
      }
      this.loading = true;
      return true;
    },
    handleError() {
      this.loading = false;
      notify.errorNotification(
        this,
        "Ocorreu um erro ao tentar enviar arquivo",
        "Tente novamente"
      );
    },
    onDelete(file) {
      api
        .deleteFile(file.response)
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
      this.$emit("image", "");
    },
  },
};
</script>

<style lang="sass" scoped>
.my-upload-component
  display: grid
  justify-content: center
  align-items: center

.avatar-uploader
  border: 1px dashed #d9d9d9
  border-radius: 6px
  cursor: pointer
  position: relative
  overflow: hidden

.avatar-uploader
  border-color: #409EFF

.avatar-uploader-icon
  font-size: 28px
  background-color: #f5f5f5
  color: #8c939d
  width: 200px
  height: 92px
  line-height: 92px
  text-align: center

.avatar
  width: 200px
  height: 92px
  display: block
</style>
