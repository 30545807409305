import RouterUtils from './utils'
import Login from '@/views/auth/Login.vue'
import Register from '@/views/auth/Register.vue'

export const authRoutes = [
  {
    path: '/entrar',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      RouterUtils.checkUserActive() ? next('/') : next()
    },
  },
  {
    path: '/cadastro',
    name: 'Register',
    component: Register,
    beforeEnter: (to, from, next) => {
      RouterUtils.checkUserActive() ? next('/') : next()
    },
  },
]
