<template>
  <el-dialog title="Alterar Senha" :visible.sync="activeDialog">
    <el-form
      ref="PasswordForm"
      :model="form"
      :rules="rules"
      :hide-required-asterisk="true"
    >
      <el-form-item label="Nova senha" prop="password" hide-required-asterisk>
        <el-input
          v-model="form.password"
          type="password"
          placeholder="Digite sua senha"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="Confirme sua senha"
        prop="checkPass"
        hide-required-asterisk
      >
        <el-input
          v-model="form.checkPass"
          type="password"
          placeholder="Confirme sua senha"
          @keyup.enter.native="submit"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="activeDialog = false">Cancelar</el-button>
      <el-button type="primary" @click="submit">Salvar</el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from "@/services/api";
import notify from "@/traits/notify";
import errors from "@/traits/errors";

export default {
  name: "UpdatePasswordDialog",
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Digite sua senha novamente"));
      } else if (value !== this.form.password) {
        callback(new Error("As duas senhas não são iguais"));
      } else {
        callback();
      }
    };

    return {
      activeDialog: this.showDialog,
      form: {
        password: "",
        checkPass: "",
      },
      rules: {
        password: [
          {
            required: true,
            message: "Digite uma senha",
            trigger: "blur",
          },
          {
            min: 6,
            max: 20,
            message: "Senha deve ter entre 6 e 20 carácteres",
            trigger: "blur",
          },
        ],
        checkPass: {
          validator: validatePass,
          trigger: "blur",
        },
      },
    };
  },
  watch: {
    showDialog: function (val) {
      if (!val) this.$emit("updateDialog", false);
    },
    activeDialog: function (val) {
      if (!val) this.$emit("updateDialog", false);
    },
  },
  methods: {
    submit() {
      this.$refs["PasswordForm"].validate((valid) => {
        if (valid) this.updateUser();
      });
    },
    updateUser() {
      const phone = this.user.phone.replace(/\D/g, "");
      api
        .updateMe(
          this.user.name,
          this.user.email,
          this.form.password,
          phone,
          this.user.city,
          this.user.state
        )
        .then(() => {
          notify.sucessNotification(
            this,
            "Sua senha foi alterada com sucesso",
            "Senha atualizada"
          );
          this.activeDialog = false;
          this.$emit("updateDialog", false);
        })
        .catch((err) => errors.handleError(this, err));
    },
  },
};
</script>
