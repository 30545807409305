<template>
  <div class="container">
    <Banner />
    <div class="content home-content">
      <h2 class="title-label">
        {{ title }}
      </h2>
      <p class="subtitle-label">
        {{ text }}
      </p>
      <el-row align="middle">
        <el-col>
          <h3 class="details-label">
            Na plataforma Correção On-line, você encontrará:
          </h3>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="image-description-content">
            <ul class="details-list">
              <li v-for="item in items" :key="item">
                <p>{{ item }}</p>
              </li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <img
            class="image"
            width="100%"
            src="@/assets/home_study.png"
            alt="Bem vindo"
          />
        </el-col>
        <el-col>
          <router-link :to="{ name: 'Themes' }" class="router-item">
            <el-button type="primary">Acessar temas</el-button>
          </router-link>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Banner from "@/views/home/components/Banner";

export default {
  name: "Home",
  components: { Banner },
  data: function () {
    return {
      title: "Bem-vindos (as) à plataforma Correção On-line",
      text: "Correção On-line é uma plataforma de redação que contribui, principalmente, com a prática de produção textual para o Exame Nacional do Ensino Médio (ENEM).",
      items: [
        "Correções detalhadas por competência;",
        "Áudios com a explicação da nota por competência;",
        "Temas disponibilizados semanalmente;",
        "Discussões temáticas;",
        "Aulas gravadas sobre redação com materiais disponíveis;",
        "Monitoria e acompanhamento através do WhatsApp e Google Meet.",
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
.container
  min-height: 89vh
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

.home-content
  display: flex
  justify-content: center
  align-items: center
  flex-wrap: wrap
  max-width: 1000px
  margin: 20px

.title-label
  text-align: center
  font-size: 30px
  font-family: 'Concert One'
  font-weight: 500
  margin-bottom: 10px

.subtitle-label
  margin: 20px 0px
  text-align: justify

.details-label
  margin-left: 20px
  font-size: 24px
  font-family: 'Concert One'
  font-weight: 500

.details-list
  margin: 20px
  line-height: 30px
</style>
