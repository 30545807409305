<template>
  <el-col :xs="24" :sm="24" :md="12">
    <div class="content">
      <h3>{{ title }}</h3>
      <el-row>
        <br />
        <el-col :xs="24" :span="14">
          <CompetenceAreaChart :title="subtitle" :data="data" :max="max" />
        </el-col>
        <el-col :xs="24" :span="10">
          <CompetenceDonutChart :title="title" :data="data" :max="max" />
        </el-col>
      </el-row>
    </div>
  </el-col>
</template>

<script>
import CompetenceAreaChart from "@/components/CompetenceAreaChart";
import CompetenceDonutChart from "@/components/CompetenceDonutChart";

export default {
  name: "PerformanceCard",
  components: {
    CompetenceAreaChart,
    CompetenceDonutChart,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    data: {
      required: true,
      type: Array,
      default: () => [],
    },
    max: {
      required: false,
      type: Number,
      default: 200,
    },
  },
};
</script>
