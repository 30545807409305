<template>
  <div>
    <el-dialog :title="title" :visible.sync="showDialog" width="30%">
      <span>{{ subtitle }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">Cancel</el-button>
        <el-button type="primary" @click="handleDelete">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: {
    title: {
      required: false,
      type: String,
      default: "Atenção",
    },
    subtitle: {
      required: false,
      type: String,
      default: "Você tem certeza que deseja excluir esse item?",
    },
    showDialog: {
      required: true,
      type: Boolean
    },
    onDelete: {
      required: true,
      type: Function
    },
  },
  methods: {
    closeDialog(){
      this.$emit("updateDialog", false);
    },
    handleDelete() {
      this.closeDialog();
      this.onDelete();
    }
  }
};
</script>
