import RouterUtils from './utils'
import EssaysReport from '@/views/reports/Essays';
import authorization from '@/traits/authorization';

export const logsRoutes = [
  {
    path: '/relatorio/redacoes',
    name: 'EssaysReport',
    component: EssaysReport,
    beforeEnter: (to, from, next) => {
      if (RouterUtils.checkUserActive()) {
        RouterUtils.checkUserHasPermission(
          [authorization.PERMISSION_LOGS_ESSAY_VIEW]
        )
          ? next()
          : next('/');
      } else {
        next('/entrar');
      }
    }
  }
];
