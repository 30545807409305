import RouterUtils from './utils'
import Users from '@/views/corrections/CorrectionPage'

export const correctionsRoutes = [
  {
    path: '/correcoes/:id',
    name: 'CorrectionPage',
    component: Users,
    props: true,
    beforeEnter: (to, from, next) => {
      RouterUtils.checkUserActive() ? next() : next('/entrar')
    },
  },
]