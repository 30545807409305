<template>
  <div class="container">
    <div class="content video-content">
      <div style="margin: 20px">
        <h2>{{ !isEdited ? "Adicionar novo vídeo" : "Editar vídeo" }}</h2>
      </div>
      <el-form
        ref="form"
        :model="video"
        :rules="rules"
        hide-required-asterisk
        label-position="top"
      >
        <el-form-item label="Título" prop="title">
          <el-input
            v-model="video.title"
            placeholder="Digite o título do vídeo"
          ></el-input>
        </el-form-item>
        <el-form-item label="Descrição" prop="description">
          <el-input type="textarea" v-model="video.description"></el-input>
        </el-form-item>
        <el-form-item label="Vimeo ID" prop="vimeo_id">
          <el-input
            v-model="video.vimeo_id"
            v-mask="'###########'"
            placeholder="Digite o Vimeo ID do vídeo"
          ></el-input>
        </el-form-item>
        <el-form-item label="Módulo" prop="playlist_id">
          <el-select
            v-model="video.playlist_id"
            placeholder="Selecione o módulo correspondente"
            filterable
            style="display: flex"
          >
            <el-option
              v-for="playlist in playlists"
              :key="playlist.id"
              :label="playlist.title"
              :value="playlist.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="files">
          <el-row type="flex" justify="center">
            <Upload
              v-on:image="updateImage"
              type="image"
              label="Imagem do vídeo"
              url="image"
              :accepted="'.png,.jpg,.jpeg'"
              :file="video.image"
            />
            <Upload
              v-on:image="updateMaterial"
              type="pdf"
              label="Material do Vídeo (PDF)"
              url="pdf"
              :accepted="'.pdf'"
              :file="video.material"
            />
          </el-row>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" @click="validForm">{{
          isEdited ? "Salvar Alterações" : "Adicionar"
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import errors from "@/traits/errors";
import notify from "@/traits/notify";
import Upload from "@/components/Upload";

export default {
  name: "PlaylistForm",
  props: {
    id: {
      required: false,
      type: Number,
    },
    playlistId: {
      required: false,
      type: Number,
    },
  },
  components: {
    Upload,
  },
  data() {
    var validateFiles = (rule, value, callback) => {
      if (this.video.image === "") {
        callback(new Error("Adicione a imagem do vídeo"));
      } else if (this.video.material === "") {
        callback(new Error("Adicione o material do vídeo"));
      } else if (this.loading) {
        callback(new Error("Aguarde os arquivos serem salvos no servidor!"));
      } else {
        callback();
      }
    };

    return {
      isEdited: this.id,
      video: {
        title: "",
        description: "",
        image: "",
        vimeo_id: "",
        material: "",
        playlist_id: this.playlistId,
      },
      playlists: [],
      rules: {
        title: {
          required: true,
          message: "Digite um título",
          trigger: "blur",
        },
        description: {
          required: true,
          message: "Digite uma descrição",
          trigger: "blur",
        },
        files: {
          validator: validateFiles,
          trigger: "blur",
        },
        vimeo_id: {
          required: true,
          message: "Digite o Vimeo ID do vídeo",
          trigger: "blur",
        },
        playlist_id: {
          required: true,
          message: "Selecione um Módulo para o vídeo",
          trigger: "blur",
        },
      },
    };
  },
  created() {
    console.log(this.playlistId)
    this.fetchPlaylists();
    if (this.isEdited) {
      this.fetchData(this.isEdited);
    }
  },
  methods: {
    fetchPlaylists() {
      api
        .getPlaylists()
        .then(({ data: playlists }) => (this.playlists = playlists))
        .catch((e) => errors.handleError(this, e));
    },
    fetchData(videoId) {
      api
        .getVideo(videoId)
        .then(({ data: video }) => (this.video = video))
        .catch((e) => errors.handleError(this, e));
    },
    validForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) this.isEdited ? this.updateVideo() : this.createVideo();
      });
    },
    createVideo() {
      api
        .createVideo(
          this.video.title,
          this.video.description,
          this.video.image,
          this.video.vimeo_id,
          this.video.material,
          this.video.playlist_id
        )
        .then(({ data }) => {
          notify.sucessNotification(
            this,
            `Vídeo ${data.title} criado com sucesso`,
            "Novo vídeo já pode ser accessado na lista de módulos"
          );
          this.resetForm();
          this.$router.replace({
            name: "PlaylistPage",
            params: { id: data.playlist_id },
          });
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    updateVideo() {
      api
        .updateVideo(
          this.isEdited,
          this.video.title,
          this.video.description,
          this.video.image,
          this.video.vimeo_id,
          this.video.material,
          this.video.playlist_id
        )
        .then(({ data }) => {
          notify.sucessNotification(
            this,
            `Vídeo ${data.title} atualizado com sucesso`,
            "O vídeo já pode ser accessado na lista de vídeos"
          );
          this.resetForm();
          this.$router.replace({
            name: "PlaylistPage",
            params: { id: data.playlist_id },
          });
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    updateImage(data) {
      this.video.image = data;
    },
    updateMaterial(data) {
      this.video.material = data;
    },
    resetForm() {
      this.video.title = "";
      this.video.description = "";
      this.video.image = "";
      this.video.vimeo_id = "";
      this.video.material = "";
      this.video.playlist_id = "";
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  min-height: 89vh
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.video-content
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  max-width: 600px
  padding: 30px
</style>
