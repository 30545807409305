
export default class errors {

  static sucessNotification(that, title, text) {
    that.$vs.notify({
      title: title,
      text: text,
      color: 'success',
      icon: 'done',
      time: 8000,
    })
  }

  static errorNotification(that, title, text){
    that.$vs.notify({
      title: title,
      text: text,
      color: 'danger',
      icon: 'highlight_off',
      time: 8000,
    })
  }
}