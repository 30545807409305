<template>
  <div>
    <apexchart type="area" :options="options" :series="series" />
  </div>
</template>

<script>
import pt_br from "apexcharts/dist/locales/pt-br.json";

export default {
  name: "CompetenceDonutChart",
  props: {
    title: {
      required: true,
      type: String,
    },
    data: {
      required: true,
      type: Array,
      default: () => [],
    },
    max: {
      required: false,
      default: 200,
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed: {
    options: function () {
      return {
        title: {
          text: this.title,
          align: "center",
        },
        chart: {
          id: "shipments",
          locales: [pt_br],
          defaultLocale: "pt-br",
          toolbar: {
            show: false,
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          min: 0,
          max: this.max,
          forceNiceScale: true,
          tickAmount: 1,
          decimalsInFloat: 0,
        },
        xaxis: {
          type: "category",
        },
      };
    },
    series: function () {
      if (!this.data.length) return [{ name: "Envios", data: [[0, 1]] }];
      return [
        {
          name: "Pontuação",
          data: this.data.map((e, index) => [index + 1, e]),
        },
      ];
    },
  },
  methods: {},
};
</script>
