<template>
  <div class="content">
    <div class="header">
      <h3>Últimos temas produzidos</h3>
    </div>
    <div v-loading="loading" class="themes-list">
      <el-row v-if="essays.length" type="flex-column">
        <el-col :xs="24" :span="12" v-for="essay in essays" :key="essay.id">
          <ThemeCardMini
            :theme="handleEssayTheme(essay)"
            :on-pressed="handleOnPressedTheme(essay)"
            :result="result(essay)"
          />
        </el-col>
      </el-row>
      <div v-else class="without-themes">
        <h3>Nenhuma redação encontrada 🙃</h3>
      </div>
    </div>
  </div>
</template>

<script>
import ThemeCardMini from '@/components/ThemeCardMini'

export default {
  name: 'ThemesMadeByUser',
  props: {
    essays: {
      required: true,
      type: Array,
      default: () => [],
    },
    loading: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  components: { ThemeCardMini },
  methods: {
    handleEssayTheme(essay) {
      if (essay.theme) return essay.theme
      return {
        image: require('@/assets/tema_livre.png'),
        title: essay.material_title,
        subtitle: 'Tema Livre',
      }
    },
    handleOnPressedTheme(essay) {
      return () =>
        this.$router.push({
          name: 'CorrectionPage',
          params: { id: `${essay.id}` },
        })
    },
    result(essay) {
      return (
        essay.competence_1 +
        essay.competence_2 +
        essay.competence_3 +
        essay.competence_4 +
        essay.competence_5
      )
    },
  },
}
</script>

<style lang="sass" scoped>
.header
  padding: 10px

.themes-list
  padding: 10px
  height: 500px
  overflow-y: scroll

.without-themes
  display: flex
  justify-content: center
  align-items: center
  height: 100%
</style>
