<template>
  <div class="loading">
    <i class="el-icon-loading" />
    {{ message }} ...
  </div>
</template>

<script>
export default {
  name: "LoadingMessage",
  props: {
    message: {
      required: false,
      type: String,
      default: "Carregando",
    },
  },
};
</script>

<style lang="sass" scoped>
.loading
  text-align: center
  color: white
  font-size: 22px
  width: 100%
</style>


