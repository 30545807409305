<template>
  <el-dialog title="Alterar Créditos" :visible.sync="activeDialog">
    <el-form ref="CreditsForm" :model="credits">
      <el-form-item label="Correção" :label-width="formLabelWidth">
        <el-input-number v-model="credits.credits" :min="0" :step="1" />
      </el-form-item>
      <el-form-item label="Monitoria" :label-width="formLabelWidth">
        <el-input-number v-model="credits.monitor_credits" :min="0" :step="1" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="activeDialog = false">Cancelar</el-button>
      <el-button type="primary" @click="updateCredits">
        Enviar
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import errors from '@/traits/errors'
import notify from '@/traits/notify'
import api from '@/services/api'

export default {
  name: 'CreditsForm',
  props: ['showDialog', 'userId'],
  data() {
    return {
      credits: {
        credits: null,
        monitor_credits: null
      },
      activeDialog: false,
      formLabelWidth: '120px',
    }
  },
  watch: {
    showDialog: function(val) {
      if (val) this.activeDialog = true
      if (this.userId) this.fetchData(this.userId)
    },
    activeDialog: function(val) {
      if (!val) this.$emit('updateDialog', false)
    },
  },
  methods: {
    fetchData(userId) {
      api
        .getUser(userId)
        .then(({ data }) => {
          this.credits = data.user.credits
        })
        .catch((err) => errors.handleError(this, err))
    },
    updateCredits() {
      api
        .updateCredits(this.userId, this.credits.credits, this.credits.monitor_credits)
        .then(() => {
          notify.sucessNotification(
            this,
            `Créditos atualizados com sucesso`,
            'Novos Créditos contabilizados'
          )
          this.resetForm()
          this.activeDialog = false
          this.$emit('updateDialog', false)
        })
        .catch((err) => errors.handleError(this, err))
    },
    resetForm() {
      this.credits = {
        credits: null,
      }
    },
  },
}
</script>
