import RouterUtils from './utils'
import Themes from '@/views/themes/Themes'
import CreateTheme from '@/views/themes/CreateTheme'
import ViewTheme from '@/views/themes/ViewTheme'

export const themesRoutes = [
  {
    path: '/temas',
    name: 'Themes',
    component: Themes,
    beforeEnter: (to, from, next) => {
      RouterUtils.checkUserActive() ? next() : next('/entrar')
    },
  },
  {
    path: '/temas/novo',
    name: 'CreateTheme',
    component: CreateTheme,
    beforeEnter: (to, from, next) => {
      if (RouterUtils.checkUserActive()) {
        RouterUtils.checkUserIsAdmin() ? next() : next('/')
      } else {
        next('/entrar')
      }
    },
  },
  {
    path: '/temas/editar/:id',
    name: 'EditTheme',
    component: CreateTheme,
    props: true,
    beforeEnter: (to, from, next) => {
      if (RouterUtils.checkUserActive()) {
        RouterUtils.checkUserIsAdmin() ? next() : next('/')
      } else {
        next('/entrar')
      }
    },
  },
  {
    path: '/temas/:id',
    name: 'ViewTheme',
    component: ViewTheme,
    props: true,
    beforeEnter: (to, from, next) => {
      RouterUtils.checkUserActive() ? next() : next('/entrar')
    },
  },
]
