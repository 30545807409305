import notify from './notify';
import store from '@/store';

export default class errors {
  static USER_INACTIVE_ERROR = 'user_inactive';
  static ESSAY_SUBMISSION_BLOCKED = 'essay_submission_blocked';

  static handleError(that, err) {
    if (!err.response)
      return notify.errorNotification(
        that,
        'Ocorreu Algum Erro',
        'Atualize a página e tente novamente'
      );
    if (err.response.status === 401) {
      notify.errorNotification(
        that,
        'Ops, parece que você foi deslogado',
        'Faça login novamente para continuar'
      );
    } else if (err.response.status === 403) {
      var error =
        err.response.data.error !== undefined
          ? err.response.data.error
          : 'Aconteceu algum erro';
      if (error === this.USER_INACTIVE_ERROR) {
        store.dispatch('logout');
        notify.errorNotification(
          that,
          'Usuário inativo',
          'Entre em contato com a administração da plataforma'
        );
      } else if (error === this.ESSAY_SUBMISSION_BLOCKED) {
        notify.errorNotification(
          that,
          'Envio de redações suspenso',
          'Aguarde até a liberação do envio novamente'
        );
      } else {
        notify.errorNotification(
          that,
          error,
          'Atualize a página e tente novamente'
        );
      }
    } else if (err.response.status === 400) {
      notify.errorNotification(
        that,
        err.response.data.error !== undefined
          ? err.response.data.error
          : 'Aconteceu algum erro',
        'Atualize a página e tente novamente'
      );
    } else {
      notify.errorNotification(
        that,
        'Ocorreu Algum Erro',
        'Atualize a página e tente novamente'
      );
    }
  }
}
