<template>
  <vs-collapse class="content page-header-content">
    <vs-collapse-item>
      <div slot="header" class="metrics-header">
        <div style="margin: 0px 10px">
          <h2>{{ title }}</h2>
        </div>
      </div>
      <slot></slot>
    </vs-collapse-item>
  </vs-collapse>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: {
      required: true,
      type: String,
    },
  },
};
</script>

<style lang="sass" scoped>
.page-header-content
  width: 90%
  max-width: 1300px
  background-color: white
  border-radius: 0px 0px 10px 10px
  padding: 10px
  margin: 0px 0px 20px 0px
</style>
