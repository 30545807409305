import store from '../store/index';

export default class authorization {
  static ROLE_ADMIN = 'administrator';
  static ROLE_PROFESSOR = 'professor';
  static ROLE_STUDENT = 'student';

  /*
    PERMISSIONS USER CRUD
  */
  static PERMISSION_USERS_VIEW = 'view user';
  static PERMISSION_USERS_ADD = 'add user';
  static PERMISSION_USERS_UPDATE = 'update user';
  static PERMISSION_USERS_DELETE = 'delete user';

  /*
   PERMISSIONS ESSAY CRUD
 */
  static PERMISSION_ESSAY_VIEW = 'view essay';
  static PERMISSION_ESSAY_ADD = 'add essay';
  static PERMISSION_ESSAY_UPDATE = 'update essay';
  static PERMISSION_ESSAY_DELETE = 'delete essay';

  /*
   PERMISSIONS CREDITS CRUD
 */
  static PERMISSION_CREDITS_UPDATE = 'update credits';

  /*
   PERMISSIONS THEME CRUD
 */
  static PERMISSION_THEME_VIEW = 'view theme';
  static PERMISSION_THEME_MADE_BY_USER_VIEW = 'view theme made by user'
  static PERMISSION_THEME_ADD = 'add theme';
  static PERMISSION_THEME_UPDATE = 'update theme';
  static PERMISSION_THEME_DELETE = 'delete theme';

  /*
   PERMISSIONS PLAYLIST CRUD
 */
  static PERMISSION_PLAYLIST_VIEW = 'view playlist';
  static PERMISSION_PLAYLIST_ADD = 'add playlist';
  static PERMISSION_PLAYLIST_UPDATE = 'update playlist';
  static PERMISSION_PLAYLIST_DELETE = 'delete playlist';

  /*
   PERMISSIONS PLAYLIST CRUD
 */
  static PERMISSION_VIDEO_VIEW = 'view video';
  static PERMISSION_VIDEO_ADD = 'add video';
  static PERMISSION_VIDEO_UPDATE = 'update video';
  static PERMISSION_VIDEO_DELETE = 'delete video';

  /*
   PERMISSIONS STALLS CRUD
 */
  static PERMISSION_STALLS_VIEW = 'view stalls';
  static PERMISSION_STALLS_ADD = 'add stalls';
  static PERMISSION_STALLS_UPDATE = 'update stalls';
  static PERMISSION_STALLS_DELETE = 'delete stalls';

  /*
    PERMISSIONS LOGS ESSAY CRUD
  */
  static PERMISSION_LOGS_ESSAY_VIEW = 'view log essay';
  static PERMISSION_LOGS_ESSAY_ALL_VIEW = 'view all log essay';
  static PERMISSION_LOGS_ESSAY_ADD = 'add log essay';
  static PERMISSION_LOGS_ESSAY_UPDATE = 'update log essay';
  static PERMISSION_LOGS_ESSAY_DELETE = 'delete log essay';

  static hasRole(roles) {
    const userRoles = store.state.roles;

    return userRoles.filter(role => roles.includes(role)).length > 0;
  }

  static hasPermission(permissions) {
    const userPermissions = store.state.permissions;

    return permissions.every(permission =>
      userPermissions.includes(permission.replace(/\s/g, '_'))
    );
  }
}
