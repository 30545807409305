<template>
  <el-dialog title="Informações da Redação" :visible.sync="activeDialog">
    <vs-row vs-justify="start" vs-align="flex-end"></vs-row>
    <vs-row vs-justify="space-between">
      <vs-col vs-w="6">
        <span class="my-label">
          <strong>Tema:</strong>
          {{ handleThemeTitle(essay) }}
        </span>
      </vs-col>
      <vs-col vs-w="6">
        <span class="my-label">
          <strong>Situação:</strong>
          {{ status[essay.status] }}
        </span>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="6">
        <span class="my-label">
          <strong>Professor:</strong>
          {{ evaluatorName }}
        </span>
      </vs-col>
      <vs-col vs-w="6">
        <span class="my-label">
          <strong>Comentário para o corretor:</strong>
          {{ essay.comment }}
        </span>
      </vs-col>
    </vs-row>
    <vs-row>
      <span class="my-label">
        <strong>Data de Envio:</strong>
        {{ essay.created_at | moment("DD/MM/YYYY") }}
      </span>
    </vs-row>
    <apexchart
      class="my-chart-class"
      width="100%"
      type="bar"
      :options="chartOptions"
      :series="series"
    />
    <vs-row vs-justify="space-around">
      <vs-button
        :disabled="!essay.correction"
        @click="showCorrection"
        >Ver Correção</vs-button
      >
      <vs-button :disabled="!essay.audio" @click="downloadFile(essay.audio)"
        >Baixar Áudio</vs-button
      >
      <vs-button :disabled="!essay.file" @click="downloadFile(essay.file)"
        >Baixar Redação</vs-button
      >
      <vs-button v-if="essay.material" @click="downloadFile(essay.material)"
        >Baixar Texto Motivador</vs-button
      >
    </vs-row>
    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="isAdmin"
        :disabled="essay.status !== 'Correcting'"
        type="warning"
        @click="unlinkCorrector"
        >Desvincular Corretor</el-button
      >
      <el-button
        v-if="isAdmin"
        :disabled="essay.status !== 'Pending'"
        type="danger"
        @click="deleteEssay"
        >Excluir Redação</el-button
      >
      <el-button
        v-if="canEdit"
        :disabled="disableEdit"
        type="primary"
        @click="handleEdit"
        >Editar</el-button
      >
      <el-button @click="activeDialog = false">Fechar</el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from "@/services/api.js";
import errors from "@/traits/errors";
import authorization from "@/traits/authorization";

export default {
  name: "EssayInfo",
  props: ["showDialog", "essayId"],
  data: function () {
    return {
      activeDialog: this.showDialog,
      essay: {
        competence_1: 0,
        competence_2: 0,
        competence_3: 0,
        competence_4: 0,
        competence_5: 0,
        theme: {},
        evaluator: {},
        status: "",
      },
      status: {
        Pending: "Enviada",
        Revised: "Corrigida",
        Correcting: "Corrigindo",
      },
      chartOptions: {
        title: {
          text: "Nota por competência",
          align: "center",
        },
        chart: {
          id: "competences",
        },
        xaxis: {
          categories: ["Comp. 1", "Comp. 2", "Comp. 3", "Comp. 4", "Comp. 5"],
        },
        yaxis: {
          min: 0,
          max: 200,
          forceNiceScale: true,
        },
      },
      series: [
        {
          name: "Nota",
          data: [0, 0, 0, 0, 0],
        },
      ],
    };
  },
  computed: {
    evaluatorName: function () {
      if (!this.essay.evaluator) return "";
      return this.essay.evaluator.name;
    },
    isAdmin() {
      return authorization.hasRole([authorization.ROLE_ADMIN]);
    },
    canEdit() {
      return authorization.hasPermission([
        authorization.PERMISSION_ESSAY_UPDATE,
      ]);
    },
    disableEdit() {
      if (this.isAdmin) return false;
      return (
        this.essay.status !== "Revised" ||
        this.essay.evaluator_id !== this.$store.state.user.id
      );
    },
  },
  watch: {
    showDialog: function (val) {
      if (!val) this.$emit("updateDialog", false);
    },
    activeDialog: function (val) {
      if (!val) this.$emit("updateDialog", false);
    },
  },
  created() {
    this.fetchData(this.essayId);
  },
  methods: {
    fetchData(id) {
      api
        .getEssay(id)
        .then(({ data: essay }) => {
          this.essay = essay;
          console.log(essay);
          this.series = [
            {
              name: "Nota",
              data: [
                essay.competence_1,
                essay.competence_2,
                essay.competence_3,
                essay.competence_4,
                essay.competence_5,
              ],
            },
          ];
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    showCorrection() {
      this.$router.push({
        name: "CorrectionPage",
        params: { id: `${this.essay.id}` },
      });
    },
    downloadFile(file) {
      window.open(file, "_blank");
    },
    handleEdit() {
      this.activeDialog = false;
      this.$emit("editCorrection", this.essayId);
    },
    handleThemeTitle(essay) {
      if (essay.material_title) return essay.material_title;
      if (essay.theme != null && essay.theme.title) return essay.theme.title;
      return "Tema livre";
    },
    unlinkCorrector() {
      if (
        confirm(
          "Tem certeza que deseja desvincular o corretor dessa redação? Essa ação é permanente."
        ) === true
      ) {
        api
          .unlinkCorrector(this.essay.id)
          .then(() => {
            this.$vs.notify({
              color: "success",
              title: "Redação desvinculada com sucesso",
              text: "Redação desvinculada com sucesso",
            });
            this.activeDialog = false;
          })
          .catch((err) => {
            errors.handleError(this, err);
          });
      }
    },
    deleteEssay() {
      if (
        confirm(
          "Tem certeza que deseja excluir essa redação? Essa ação é permanente."
        ) === true
      ) {
        api
          .deleteEssay(this.essay.id)
          .then(() => {
            this.$vs.notify({
              color: "success",
              title: "Redação excluída com sucesso",
              text: "Redação excluída com sucesso",
            });
            this.activeDialog = false;
          })
          .catch((err) => {
            errors.handleError(this, err);
          });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.my-label
  font-size: 15px
  padding: 3px 10px
  word-break: break-word

.my-chart-class
  padding: 25px 10px
</style>
