<template>
  <div class="container">
    <div class="content essay-no-theme">
      <div align="center">
        <h2 style="margin: 10px">Redação - tema livre</h2>
        <p style="margin: 10px">
          Envie a redação sobre o tema que deseja. Lembre-se de anexar os textos
          motivadores sobre o tema.
        </p>
      </div>
      <div align="center" style="margin: 20px">
        <el-form
          ref="form"
          :model="essay"
          :rules="rules"
          hide-required-asterisk
        >
          <el-form-item label="Título do tema" prop="material_title">
            <el-input
              v-model="essay.material_title"
              placeholder="Digite o título do tema"
            ></el-input>
          </el-form-item>
          <el-form-item prop="files">
            <vs-row vs-justify="center" vs-align="center">
              <upload
                v-on:image="updateMaterial"
                type="text"
                label="Texto motivador"
                url="text"
                :accepted="'.jpg,.png,.docx,.pdf,.doc'"
              />
              <upload
                v-on:image="updateFile"
                type="essay"
                label="Redação"
                url="file"
                :accepted="'.jpg,.png,.docx,.pdf,.doc'"
              />
            </vs-row>
          </el-form-item>
          <el-form-item prop="textarea">
            <vs-textarea
              label="Deixe aqui alguma observação para o corretor"
              v-model="essay.textarea"
            />
          </el-form-item>
        </el-form>
      </div>
      <div align="center">
        <el-button
          @click="validForm"
          type="primary"
          :disabled="handleCredits || !submissionAvailable || sending"
          >Enviar Redação</el-button
        >
        <vs-row vs-justify="center" v-if="handleCredits">
          <span class="credits-alert">Saldo de créditos insuficiente</span>
        </vs-row>
        <br />
        <vs-row vs-justify="center" v-if="!submissionAvailable">
          <span class="block-alert">Envio de redações suspenso</span>
        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import notify from "@/traits/notify";
import errors from "@/traits/errors";
import Upload from "@/components/Upload";

export default {
  name: "EssayWithoutTheme",
  components: {
    Upload,
  },
  data() {
    var validateFiles = (rule, value, callback) => {
      if (this.essay.material === "") {
        callback(new Error("Adicione o texto motivador"));
      } else if (this.essay.file === "") {
        callback(new Error("Adicione o arquivo da redação"));
      } else if (this.loading) {
        callback(new Error("Aguarde os arquivos serem salvos no servidor!"));
      } else {
        callback();
      }
    };

    return {
      loading: false,
      sending: false,
      submissionAvailable: true,
      essay: {
        textarea: "",
        file: "",
        material: "",
        material_title: "",
      },
      rules: {
        files: {
          validator: validateFiles,
          trigger: "blur",
        },
        material_title: {
          required: true,
          message: "Digite um título para o tema",
          trigger: "blur",
        },
      },
    };
  },
  created() {
    this.fetchSubmissionStatus();
  },
  computed: {
    handleCredits() {
      if (!this.$store.state.user || !this.$store.state.user.credits)
        return true;
      return this.$store.state.user.credits.credits === 0;
    },
    buttonMessage() {
      return this.sending ? "Enviando..." : "Enviar Redação";
    },
  },
  methods: {
    updateFile(data) {
      this.essay.file = data;
    },
    updateMaterial(data) {
      this.essay.material = data;
    },
    validForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) this.submit();
      });
    },
    fetchSubmissionStatus() {
      api
        .getEssaySubmissionStatus()
        .then(({ data }) => {
          this.submissionAvailable = data;
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    submit() {
      if (!this.sending) {
        this.sending = true;
        api
          .createEssay(
            this.essay.file,
            this.essay.textarea,
            null,
            this.essay.material,
            this.essay.material_title
          )
          .then(() => {
            this.sending = false;
            notify.sucessNotification(
              this,
              `Sua redação foi enviada com sucesso`,
              'Aguarde a correção. Ela será enviada em até 2 dias. Você poderá acessar suas correções em "Minhas Redações"'
            );
            this.resetForm();
            this.$router.replace(`/redacoes`);
          })
          .catch((err) => {
            this.sending = false;
            errors.handleError(this, err);
          });
      }
    },
    resetForm() {
      this.essay.textarea = "";
      this.essay.file = "";
      this.essay.material = "";
      this.essay.material_title = "";
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  min-height: 89vh
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.essay-no-theme
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.el-form-item__error
  position: inherit !important

.credits-alert
  color: red
  font-size: 13px

.block-alert
  color: red
  font-size: 13px
</style>
