<template>
  <div class="comment-container">
    <div class="comment-title">{{ title }}</div>
    <div class="comment-content">
      Sua nota nesta competência é: <strong>{{ grade }}</strong>
    </div>
    <div class="comment-content">
      <p>
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommentBlock",
  props: {
    title: {
      required: true,
    },
    grade: {
      required: true,
    },
    description: {
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.comment-container
  margin: 10px
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.3)
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.3)
  border-radius: 5px 5px 5px 5px

.comment-title
  background: #0767ca
  padding: 10px
  border-radius: 5px 5px 0px 0px
  color: white
  font-weight: bold

.comment-content
  background: white
  color: #3a4040
  padding: 6px
  border-radius: 0px 0px 5px 5px
  white-space: pre-line
</style>