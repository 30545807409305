<template>
  <div class="content">
    <UpdatePasswordDialog
      v-if="showPasswordDialog"
      :show-dialog.sync="showPasswordDialog"
      :user.sync="user"
      v-on:updateDialog="(value) => (showPasswordDialog = value)"
    />
    <div class="header">
      <h3>{{ pageTitle }}</h3>
    </div>
    <el-form
      ref="form"
      :model="user"
      :rules="rules"
      :hide-required-asterisk="true"
    >
      <el-form-item label="Nome" class="custom-form-item" prop="name">
        <el-input
          placeholder="Nome"
          v-model="user.name"
          :disabled="disabledForm"
          class="custom-input"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="yourselfPage || canSeeUsers"
        label="Email"
        class="custom-form-item"
        prop="email"
      >
        <el-input
          placeholder="Email"
          v-model="user.email"
          :disabled="disabledForm"
          class="custom-input"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="yourselfPage || canSeeUsers"
        label="Telefone"
        class="custom-form-item"
        prop="phone"
      >
        <el-input
          v-mask="'(##) #####-####'"
          placeholder="Telefone"
          v-model="user.phone"
          :disabled="disabledForm"
          class="custom-input"
        ></el-input>
      </el-form-item>
      <el-form-item label="Cidade" class="custom-form-item" prop="city">
        <el-input
          placeholder="Cidade"
          v-model="user.city"
          :disabled="disabledForm"
          class="custom-input"
        ></el-input>
      </el-form-item>
      <el-form-item label="Estado" class="custom-form-item" prop="state">
        <el-input
          placeholder="Estado"
          v-model="user.state"
          :disabled="disabledForm"
          class="custom-input"
        ></el-input>
      </el-form-item>
    </el-form>
    <br />
    <el-row type="flex" justify="end">
      <el-button
        v-if="yourselfPage"
        type="primary"
        @click="handleChangeInfo"
        size="small"
      >
        {{ changeInfoLabel }}
      </el-button>
      <el-button
        v-if="yourselfPage"
        type="primary"
        plain
        @click="handleChangePassword"
        :disabled="!disabledForm"
        size="small"
      >
        Alterar Senha
      </el-button>
    </el-row>
  </div>
</template>

<script>
import api from "@/services/api";
import notify from "@/traits/notify";
import errors from "@/traits/errors";
import auth from "@/traits/authorization";
import UpdatePasswordDialog from "./UpdatePasswordDialog";

export default {
  name: "UserInfo",
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  components: { UpdatePasswordDialog },
  data() {
    return {
      infoExpanded: true,
      disabledForm: true,
      showPasswordDialog: false,
      user: {
        id: null,
        city: null,
        email: "",
        name: "",
        phone: null,
        state: null,
        password: null,
      },
      rules: {
        name: {
          required: true,
          message: "Digite seu nome",
          trigger: "blur",
        },
        email: [
          {
            required: true,
            message: "Digite um endereço de e-mail",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor, digite um e-mail válido",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Digite seu número de telefone",
            trigger: "blur",
            pattern: /^\(\d{2}\) \d{5}-\d{4}$/,
          },
        ],
        state: {
          required: true,
          message: "Digite seu Estado",
          trigger: "blur",
        },
        city: {
          required: true,
          message: "Digite sua cidade",
          trigger: "blur",
        },
      },
    };
  },
  created() {
    this.updateProps();
  },
  watch: {
    id: function () {
      if (this.id) this.updateProps();
    },
  },
  computed: {
    pageTitle() {
      if (this.yourselfPage) return "Minhas Informações";
      else return "Informações do usuário";
    },
    changeInfoLabel() {
      if (this.disabledForm) return "Alterar Informações";
      return "Salvar Alterações";
    },
    yourselfPage() {
      return this.$store.state.user.id === this.id;
    },
    canSeeUsers() {
      return auth.hasPermission([auth.PERMISSION_USERS_VIEW]);
    },
  },
  methods: {
    async fetchMyData() {
      const { data } = await api.getMe();
      this.handleUserResponse(data);
    },
    async fetchPrivateData() {
      const { data } = await api.getUser(this.id);
      this.handleUserResponse(data);
    },
    async fetchPublicData() {
      const { data } = await api.getPublicUser(this.id);
      this.handleUserResponse(data);
    },
    handleUserResponse(data) {
      this.user = {
        ...data.user,
        phone: data.user.phone?.replace(
          /^(\d{2})(\d{5})(\d{4})$/,
          "($1) $2-$3"
        ),
      };
    },
    handleChangeInfo() {
      if (this.disabledForm) this.disabledForm = false;
      else {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.updateUser();
            this.disabledForm = true;
          }
        });
      }
    },
    handleChangePassword() {
      this.showPasswordDialog = true;
    },
    updateUser() {
      const phone = this.user.phone.replace(/\D/g, "");
      api
        .updateMe(
          this.user.name,
          this.user.email,
          this.user.password,
          phone,
          this.user.city,
          this.user.state
        )
        .then(() => {
          notify.sucessNotification(
            this,
            "Suas informações foram alteradas com sucesso",
            "Informações atualizadas"
          );
        })
        .catch((err) => errors.handleError(this, err));
    },
    updateProps() {
      if (this.yourselfPage) this.fetchMyData();
      else if (this.canSeeUsers) this.fetchPrivateData();
      else this.fetchPublicData();
    },
  },
};
</script>

<style lang="sass" scoped>
.header
  padding: 10px

.custom-form-item
  margin-bottom: 5px !important
</style>
