<template>
  <div class="container">
    <div class="content essays-content">
      <correction-form
        v-if="showDialogEditCorrection"
        v-on:updateDialog="handleDialogEditCorrection"
        :show-dialog.sync="showDialogEditCorrection"
        :essay-id="essayId"
        :is-edited="editCorrection"
      />
      <essay-info
        v-if="showDialogInfo"
        v-on:updateDialog="handleDialogInfo"
        v-on:editCorrection="handleEditCorrection"
        :show-dialog.sync="showDialogInfo"
        :essay-id="essayId"
      />

      <h2 style="margin: 10px 10px">Relatório de Redações</h2>
      <div class="filter-container">
        <el-input
          v-model="query.keyword"
          placeholder="Buscar"
          style="width: 200px;margin: 10px 5px"
          @keyup.enter.native="handleFilter"
        />
        <el-select
          v-model="query.action"
          placeholder="Ação"
          clearable
          style="width: 200px;margin: 10px 5px"
          @change="handleFilter"
        >
          <el-option
            v-for="[key, value] in Object.entries(actions)"
            :key="key"
            :label="value"
            :value="key"
          />
        </el-select>
        <el-select
          v-model="query.status"
          placeholder="Status"
          clearable
          style="width: 200px;margin: 10px 5px"
          @change="handleFilter"
        >
          <el-option
            v-for="[key, value] in Object.entries(status)"
            :key="key"
            :label="value"
            :value="key"
          />
        </el-select>
        <el-date-picker
          v-model="query.date_range"
          type="daterange"
          range-separator="-"
          start-placeholder="Data início"
          end-placeholder="Data final"
          style="margin: 10px 5px"
          format="dd/MM/yyyy"
          @change="handleFilter"
        ></el-date-picker>
        <el-button
          type="primary"
          icon="el-icon-search"
          style="width: 120px;margin: 10px 5px"
          @click="handleFilter"
        >Buscar</el-button>
      </div>

      <el-table v-loading="loading" :data="reports" fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="Professor associado" width="240">
          <template slot-scope="scope">
            <span>{{ scope.row.userAffected.name }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="E-email do professor" width="240">
          <template slot-scope="scope">
            <span>{{ scope.row.userAffected.email }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Anterior" width="100">
          <template slot-scope="scope">
            <span>{{ status[scope.row.old_status] }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Posterior" width="100">
          <template slot-scope="scope">
            <span>{{ status[scope.row.new_status] }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Data e hora" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.created_at | moment("DD/MM/YYYY HH:mm") }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Ação" width="180">
          <template slot-scope="scope">
            <span>{{ actions[scope.row.action] }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Responsável" width="230">
          <template slot-scope="scope">
            <span>{{ scope.row.userAction.name }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Redação" width="100">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-warning"
              @click="handleShowEssayInfo(scope.row.essay_id)"
              size="small"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="pager, total"
        :total="query.total"
        :page-size="query.limit"
        :limit.sync="query.limit"
        :current-page="query.page"
        :pager-count="5"
        @current-change="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import errors from "@/traits/errors";
import CorrectionForm from "@/views/essays/components/CorrectionForm";
import EssayInfo from "@/views/essays/components/EssayInfo";

export default {
  name: "EssaysReports",
  components: {
    CorrectionForm,
    EssayInfo,
  },
  data() {
    return {
      reports: [],
      loading: false,
      query: {
        total: 0,
        page: 1,
        limit: 10,
        keyword: "",
        action: "",
        status: "",
        date_range: "",
      },
      essayId: null,
      showDialogInfo: false,
      showDialogEditCorrection: false,
      editCorrection: true,
      actions: {
        arrest_to_correction: "Pegou para corrigir",
        send_correction: "Enviou uma correção",
        unlink_corrector: "Desvinculou da redação",
        delete_essay: "Excluiu uma redação",
        edit_correction: "Editou uma correção",
      },
      status: {
        Pending: "Enviada",
        Revised: "Corrigida",
        Correcting: "Corrigindo",
        Deleted: "Deletada",
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleFilter() {
      this.query.page = 1;
      console.log(this.query);
      this.getList();
    },
    getList() {
      this.loading = true;
      api
        .getEssaysReports(this.query)
        .then(({ data }) => {
          const { data: reports } = data;
          this.query.total = Number(data.total);
          this.reports = reports;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          errors.handleError(this, err);
        });
    },
    handleChangePage(page) {
      this.query.page = page;
      this.getList();
    },
    handleShowEssayInfo(essayId) {
      this.essayId = essayId;
      this.showDialogInfo = true;
    },
    handleDialogInfo(value) {
      this.showDialogInfo = value;
      this.getList();
    },
    handleEditCorrection(essayId) {
      this.showDialogEditCorrection = true;
      this.essayId = essayId;
    },
    handleDialogEditCorrection(value) {
      this.showDialog = value;
      this.getList();
    },
  },
};
</script>


<style lang="sass" scoped>
.container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start

.essays-content
  width: 90%
  margin: 20px
  max-width: 1400px
  margin-top: 80px
</style>
