<template>
  <div class="container">
    <div class="content module-content">
      <el-row
        v-if="hasRole([roleAdmin])"
        type="flex"
        justify="end"
        style="padding-bottom: 10px"
      >
        <el-button type="primary" icon="el-icon-plus" @click="handleAddVideo">
          Vídeo ao módulo
        </el-button>
      </el-row>
      <LoadingMessage v-if="loading" class="loading-message" />
      <el-row v-else>
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="4">
          <NwImg
            :src="playlist.image"
            alt="Imagem do Módulo"
            class="image-header"
          />
        </el-col>
        <el-col :xs="0" :span="1" />
        <el-col
          :xs="24"
          :sm="17"
          :md="17"
          :lg="17"
          :xl="19"
          class="content-header"
        >
          <h2>{{ playlist.title }}</h2>
          <p style="font-size: 17px">
            {{ playlist.description }}
          </p>
          <el-row v-if="playlist" type="flex">
            <p v-if="playlist" class="duration-bottom">
              {{ duration }}
            </p>
            <p class="duration-bottom">
              {{ videosQuantity }}
            </p>
          </el-row>
        </el-col>
      </el-row>
      <div style="padding: 10px">
        <h2>Aulas</h2>
      </div>
      <LoadingMessage v-if="loading" class="loading-message" />
      <VideoCard
        v-for="video in videos"
        :key="video.id"
        :video="video"
        :is-checked="alreadyWatched(video)"
        @updateList="(value) => fetchModule(playlist.id)"
      />
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import errors from "@/traits/errors";
import authorization from "@/traits/authorization";
import VideoCard from "@/components/VideoCard";
import LoadingMessage from "@/components/LoadingMessage";

export default {
  name: "PlaylistPage",
  props: ["id"],
  components: { VideoCard, LoadingMessage },
  data() {
    return {
      loading: false,
      playlistId: this.id,
      playlist: {},
      watchedVideos: [],
    };
  },
  computed: {
    roleAdmin() {
      return authorization.ROLE_ADMIN;
    },
    duration() {
      let hour = Math.floor(this.playlist.duration / 60).toLocaleString(
        "en-US",
        {
          minimumIntegerDigits: 2,
        }
      );
      let minutes = (this.playlist.duration % 60).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      });
      return `Duração: ${hour}:${minutes}`;
    },
    videosQuantity() {
      if (this.playlist.videos) {
        var videos = this.playlist.videos.length;
        return `${videos} Aula${videos > 1 ? "s" : ""}`;
      }
      return "";
    },
    videos: {
      get() {
        if (this.playlist.videos) return this.playlist.videos;
        return [];
      },
      set(value) {
        console.log(value);
        this.playlist.videos = value;
      },
    },
  },
  created() {
    this.fetchModule(this.id);
    this.fetchWatchedVideos();
  },
  methods: {
    fetchModule(playlistId) {
      this.loading = true;
      api
        .getPlaylist(playlistId)
        .then(({ data: playlist }) => (this.playlist = playlist))
        .catch((e) => errors.handleError(this, e))
        .finally(() => (this.loading = false));
    },
    handleAddVideo() {
      this.$router.push({
        name: "CreateVideo",
        params: { playlistId: Number(this.playlistId) },
      });
    },
    hasRole(roles) {
      return authorization.hasRole(roles);
    },
    fetchWatchedVideos() {
      api
        .getVideosWatchedByMe()
        .then(({ data: videos }) => (this.watchedVideos = videos))
        .catch((e) => errors.handleError(this, e));
    },
    alreadyWatched(video) {
      return this.watchedVideos.some((element) => element.id === video.id);
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  min-height: 89vh
  width: 100%
  display: flex
  justify-content: center
  align-items: flex-start

.module-content
  display: flex
  flex-direction: column
  max-width: 1100px
  width: 90%
  padding: 25px
  margin-top: 40px

.content-header
  padding: 15px
  min-height: 150px
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: baseline

.image-header
  width: 100%
  border-radius: 15px
  height: 150px
  object-fit: cover

.duration-bottom
  padding: 2px 8px
  background-color: #2d2d2ddd
  color: white
  border-radius: 5px
  margin-right: 10px

.loading-message
  color: black !important
  padding: 40px
</style>
