import store from '@/store/index'
import authorization from '@/traits/authorization'

export default class RouterUtils {
  static checkUserActive() {
    store.dispatch('fetchAccessToken')
    if (store.state.accessToken) {
      if (store.state.user.id === undefined) store.dispatch('fetchUser')
      return true
    } else {
      return false
    }
  }

  static checkUserIsAdmin(){
    return authorization.hasRole(authorization.ROLE_ADMIN)
  }

  static checkUserHasPermission(permissions){
    return authorization.hasPermission(permissions)
  }
}


