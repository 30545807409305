import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/views/home/Home.vue';
import RouterUtils from './utils';

import { authRoutes } from './auth';
import { essaysRoutes } from './essays';
import { materialsRoutes } from './materials';
import { themesRoutes } from './themes';
import { adminRoutes } from './admin';
import { videosRoutes } from './videos';
import { logsRoutes } from './logs';
import { userRoutes } from './user';
import { correctionsRoutes } from './corrections';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    beforeEnter: (to, from, next) => {
      RouterUtils.checkUserActive() ? next() : next('/entrar');
    }
  },
  ...adminRoutes,
  ...authRoutes,
  ...essaysRoutes,
  ...materialsRoutes,
  ...themesRoutes,
  ...videosRoutes,
  ...logsRoutes,
  ...userRoutes,
  ...correctionsRoutes,
  {
    path: '*',
    redirect: '/entrar'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
