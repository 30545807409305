<template>
  <el-dialog title="Enviar Correção" :visible.sync="activeDialog" :close-on-click-modal="false">
    <vs-row vs-justify="start" vs-align="flex-end">
      <el-form ref="form" :model="form" :rules="rules" hide-required-asterisk>
        <el-form-item prop="files">
          <vs-row vs-justify="center" vs-align="center">
            <upload
              v-on:image="updateCorrection"
              label="Correção"
              type="correction"
              url="correction"
              accepted=".pdf"
              :file="form.correction"
            />
            <upload
              v-on:image="updateAudio"
              label="Áudio"
              type="correction"
              url="correction"
              :accepted="'.mp3,.ogg,.m4a,.mp4'"
              :file="form.audio"
            />
          </vs-row>
        </el-form-item>
        <el-form-item
          label="Competência 1"
          prop="competence_1"
          :label-width="formLabelWidth"
        >
          <el-input-number
            v-model="form.competence_1"
            :min="0"
            :max="200"
            :step="20"
          />
        </el-form-item>
        <el-form-item prop="competence_1_description">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2 }"
            placeholder="Deixe aqui alguma observação para a competência 1"
            v-model="form.competence_1_description"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="Competência 2"
          prop="competence_2"
          :label-width="formLabelWidth"
        >
          <el-input-number
            v-model="form.competence_2"
            :min="0"
            :max="200"
            :step="20"
          />
        </el-form-item>
        <el-form-item prop="competence_2_description">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2 }"
            placeholder="Deixe aqui alguma observação para a competência 2"
            v-model="form.competence_2_description"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="Competência 3"
          prop="competence_3"
          :label-width="formLabelWidth"
        >
          <el-input-number
            v-model="form.competence_3"
            :min="0"
            :max="200"
            :step="20"
          />
        </el-form-item>
        <el-form-item prop="competence_3_description">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2 }"
            placeholder="Deixe aqui alguma observação para a competência 3"
            v-model="form.competence_3_description"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="Competência 4"
          prop="competence_4"
          :label-width="formLabelWidth"
        >
          <el-input-number
            v-model="form.competence_4"
            :min="0"
            :max="200"
            :step="20"
          />
        </el-form-item>
        <el-form-item prop="competence_4_description">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2 }"
            placeholder="Deixe aqui alguma observação para a competência 4"
            v-model="form.competence_4_description"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="Competência 5"
          prop="competence_5"
          :label-width="formLabelWidth"
        >
          <el-input-number
            v-model="form.competence_5"
            :min="0"
            :max="200"
            :step="20"
          />
        </el-form-item>
        <el-form-item prop="competence_5_description">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2 }"
            placeholder="Deixe aqui alguma observação para a competência 5"
            v-model="form.competence_5_description"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </vs-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="activeDialog = false">Cancelar</el-button>
      <el-button type="primary" @click="validForm"> Enviar </el-button>
    </span>
  </el-dialog>
</template>

<script>
import Upload from "../../../components/Upload";
import api from "../../../services/api";
import notify from "../../../traits/notify";
import errors from "../../../traits/errors";

export default {
  components: {
    Upload,
  },
  props: ["showDialog", "essayId", "isEdited"],
  data() {
    var validateFiles = (rule, value, callback) => {
      if (this.form.correction === "") {
        callback(new Error("Adicione o arquivo da correção"));
      } else if (this.form.audio === "") {
        callback(new Error("Adicione o arquivo de áudio"));
      } else {
        callback();
      }
    };

    return {
      activeDialog: this.showDialog,
      form: {
        competence_1: "",
        competence_1_description: "",
        competence_2: "",
        competence_2_description: "",
        competence_3: "",
        competence_3_description: "",
        competence_4: "",
        competence_4_description: "",
        competence_5: "",
        competence_5_description: "",
        audio: "",
        correction: "",
      },
      rules: {
        files: {
          validator: validateFiles,
          trigger: "blur",
        },
        competence_1: {
          required: true,
          message: "Digite a nota da competência 1",
          trigger: "blur",
        },
        competence_1_description: {
          required: false,
          message: "Digite uma observação para a competência 1",
          trigger: "blur",
        },
        competence_2: {
          required: true,
          message: "Digite a nota da competência 2",
          trigger: "blur",
        },
        competence_2_description: {
          required: false,
          message: "Digite uma observação para a competência 2",
          trigger: "blur",
        },
        competence_3: {
          required: true,
          message: "Digite a nota da competência 3",
          trigger: "blur",
        },
        competence_3_description: {
          required: false,
          message: "Digite uma observação para a competência 3",
          trigger: "blur",
        },
        competence_4: {
          required: true,
          message: "Digite a nota da competência 4",
          trigger: "blur",
        },
        competence_4_description: {
          required: false,
          message: "Digite uma observação para a competência 4",
          trigger: "blur",
        },
        competence_5: {
          required: true,
          message: "Digite a nota da competência 5",
          trigger: "blur",
        },
        competence_5_description: {
          required: false,
          message: "Digite uma observação para a competência 5",
          trigger: "blur",
        },
      },
      formLabelWidth: "120px",
    };
  },
  created() {
    if (this.isEdited) this.fetchEssay(this.essayId);
  },
  watch: {
    showDialog: function (val) {
      if (!val) this.$emit("updateDialog", false);
    },
    activeDialog: function (val) {
      if (!val) this.$emit("updateDialog", false);
    },
  },
  methods: {
    validForm() {
      this.$refs["form"].validate((valid) => {
        if (valid)
          this.isEdited ? this.updateEssayCorrection() : this.updateEssay();
      });
    },
    fetchEssay(id) {
      api
        .getEssay(id)
        .then(({ data: essay }) => {
          this.form = { ...essay };
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    updateEssay() {
      api
        .updateEssay(
          this.essayId,
          this.form.competence_1,
          this.form.competence_1_description,
          this.form.competence_2,
          this.form.competence_2_description,
          this.form.competence_3,
          this.form.competence_3_description,
          this.form.competence_4,
          this.form.competence_4_description,
          this.form.competence_5,
          this.form.competence_5_description,
          this.form.correction,
          this.form.audio
        )
        .then(() => {
          notify.sucessNotification(
            this,
            `Correção foi enviada com sucesso`,
            "O Aluno já pode acessar a correção na plataforma"
          );
          this.resetForm();
          this.activeDialog = false;
          this.$emit("updateDialog", false);
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    updateEssayCorrection() {
      api
        .updateCorrection(
          this.essayId,
          this.form.competence_1,
          this.form.competence_1_description,
          this.form.competence_2,
          this.form.competence_2_description,
          this.form.competence_3,
          this.form.competence_3_description,
          this.form.competence_4,
          this.form.competence_4_description,
          this.form.competence_5,
          this.form.competence_5_description,
          this.form.correction,
          this.form.audio
        )
        .then(() => {
          notify.sucessNotification(
            this,
            `Correção foi atualizada com sucesso`,
            "O Aluno já pode acessar a nova correção na plataforma"
          );
          this.resetForm();
          this.activeDialog = false;
          this.$emit("updateDialog", false);
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    updateCorrection(data) {
      this.form.correction = data;
    },
    updateAudio(data) {
      this.form.audio = data;
    },
    resetForm() {
      this.form.competence_1 = "";
      this.form.competence_1_description = "";
      this.form.competence_2 = "";
      this.form.competence_2_description = "";
      this.form.competence_3 = "";
      this.form.competence_3_description = "";
      this.form.competence_4 = "";
      this.form.competence_4_description = "";
      this.form.competence_5 = "";
      this.form.competence_5_description = "";
      this.form.audio = "";
      this.form.correction = "";
    },
  },
};
</script>
