<template>
  <div class="container">
    <metrics />
    <div class="content essays-content">
      <correction-form
        v-if="showDialog"
        v-on:updateDialog="handleDialog"
        :show-dialog.sync="showDialog"
        :essay-id="essayId"
        :is-edited="editCorrection"
      />
      <essay-info
        v-if="showDialogInfo"
        v-on:updateDialog="handleDialogInfo"
        v-on:editCorrection="handleEditCorrection"
        :show-dialog.sync="showDialogInfo"
        :essay-id="essayId"
      />
      <div style="margin: 10px 10px">
        <h2 style="margin-bottom: 10px">{{ tableTitle }}</h2>
      </div>
      <div class="filter-container">
        <el-input
          v-model="query.keyword"
          placeholder="Buscar"
          style="width: 200px; margin: 10px 10px"
          @keyup.enter.native="handleFilter"
        />
        <el-select
          v-model="query.status"
          placeholder="Situação"
          clearable
          style="width: 120px; margin: 10px 10px"
          @change="handleFilter"
        >
          <el-option
            v-for="status in statusArray"
            :key="status.key"
            :label="status.value"
            :value="status.key"
          />
        </el-select>
        <el-button
          type="primary"
          icon="el-icon-search"
          style="width: 120px; margin: 10px 10px"
          @click="handleFilter"
          >Buscar</el-button
        >
      </div>

      <el-table
        v-loading="loading"
        :data="essays"
        fit
        highlight-current-row
        :row-class-name="rowState"
        style="width: 100%"
      >
        <el-table-column align="center" label="ID" width="60">
          <template slot-scope="scope">
            <span>
              {{ scope.row.id }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!isStudent"
          align="center"
          label="Nome"
          width="180"
        >
          <template slot-scope="scope">
            <span
              @click="() => handleClickedRow(scope.row)"
              class="essay-router-item"
            >
              {{ scope.row.user.name }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          label="Tema"
          :width="isStudent ? 400 : 320"
        >
          <template slot-scope="scope">
            <a
              :href="handleThemeUrl(scope.row)"
              target="_blank"
              class="essay-router-item"
              >{{ handleThemeTitle(scope.row) }}</a
            >
          </template>
        </el-table-column>

        <el-table-column align="center" label="Pontuação" width="100">
          <template slot-scope="scope">
            <span>{{ calculateResult(scope.row) }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" label="Professor" width="160">
          <template slot-scope="scope">
            <span>{{ handleEvaluator(scope.row) }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" label="Situação" width="110">
          <template slot-scope="scope">
            <div :class="`status-component-${scope.row.status}`">
              <span>{{ status[scope.row.status] }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          v-if="isStudent"
          align="center"
          label="Data de Envio"
          width="180"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.created_at | moment("DD/MM/YYYY") }}</span>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          label="Ações"
          :width="isStudent ? 250 : 400"
        >
          <template slot-scope="scope">
            <div class="table-buttons">
              <vs-button
                icon="edit"
                size="small"
                v-if="!isStudent"
                :disabled="scope.row.status !== 'Pending'"
                @click="handleCorrect(scope.row)"
                >Corrigir</vs-button
              >
              <vs-button
                icon="arrow_upward"
                size="small"
                v-if="!isStudent"
                :disabled="handleSendButton(scope.row)"
                @click="openCorrectionForm(scope.row)"
                >Correção</vs-button
              >
              <vs-button
                icon="visibility"
                size="small"
                :disabled="scope.row.status !== 'Revised'"
                @click="handleShowCorrection(scope.row)"
                >Correção</vs-button
              >
              <vs-button
                icon="info"
                size="small"
                @click="handleRevised(scope.row)"
                >Informações</vs-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="pager"
        :total="query.total"
        :page-size="query.limit"
        :limit.sync="query.limit"
        :current-page="query.page"
        :pager-count="5"
        @current-change="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import api from "../../services/api";
import errors from "../../traits/errors";
import authorization from "../../traits/authorization";
import CorrectionForm from "./components/CorrectionForm";
import EssayInfo from "./components/EssayInfo";
import Metrics from "./components/Metrics";

export default {
  name: "Essays",
  components: {
    CorrectionForm,
    EssayInfo,
    Metrics,
  },
  data() {
    return {
      essays: [],
      essayId: "",
      loading: false,
      showDialog: false,
      showDialogInfo: false,
      editCorrection: false,
      query: {
        total: 0,
        page: 1,
        limit: 10,
        keyword: "",
        status: "",
      },
      status: {
        Pending: "Enviada",
        Revised: "Corrigida",
        Correcting: "Corrigindo",
      },
      statusArray: [
        {
          key: "Pending",
          value: "Enviada",
        },
        {
          key: "Revised",
          value: "Corrigida",
        },
        {
          key: "Correcting",
          value: "Corrigindo",
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  computed: {
    tableTitle() {
      return this.isStudent ? "Minhas Redações" : "Redações";
    },
    isStudent() {
      return authorization.hasRole([authorization.ROLE_STUDENT]);
    },
    canSeeUserPage: function () {
      return authorization.hasPermission([
        authorization.PERMISSION_THEME_MADE_BY_USER_VIEW,
      ]);
    },
  },
  methods: {
    handleFilter() {
      this.query.page = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      api
        .getEssays(this.query)
        .then(({ data }) => {
          const { data: essays } = data;
          this.query.total = Number(data.total);
          this.essays = essays;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          errors.handleError(this, err);
        });
    },
    handleCorrect(row) {
      this.$vs.dialog({
        type: "confirm",
        color: "warning",
        title: `Corrigir Redação de ${row.user.name}`,
        acceptText: "Aceitar",
        cancelText: "Cancelar",
        text: 'Ao continuar, a redação escolhida passará para o status "Corrigindo" e você será atribuído como corretor, deseja continuar?',
        accept: () => this.acceptAlert(row.id),
        cancel: this.cancelAlert,
      });
    },
    acceptAlert(id) {
      api
        .arrestEssay(id)
        .then(({ data }) => {
          this.downloadEssay(data.file);
          this.getList();
          this.$vs.notify({
            color: "success",
            title: "Alteração realizada",
            text: "Iniciando download",
          });
        })
        .catch((err) => {
          this.loading = false;
          errors.handleError(this, err);
        });
    },
    downloadEssay(file) {
      window.open(file, "_blank");
    },
    cancelAlert() {
      this.$vs.notify({
        color: "success",
        title: "Operação cancelada",
      });
    },
    rowState({ row, rowIndex }) {
      if (row === undefined || rowIndex === undefined) return "";
      return row.status === "Pending"
        ? "primary-row"
        : row.status === "Revised"
        ? "success-row"
        : "warning-row";
    },
    calculateResult(data) {
      if (data.status === "Correcting" || data.status === "Pending") return "-";
      const result =
        data.competence_1 +
        data.competence_2 +
        data.competence_3 +
        data.competence_4 +
        data.competence_5;
      return result;
    },
    handleShowCorrection(row) {
      this.$router.push({
        name: "CorrectionPage",
        params: { id: `${row.id}` },
      });
    },
    handleRevised(row) {
      this.showDialogInfo = true;
      this.essayId = row.id;
    },
    handleEvaluator(data) {
      if (data.status === "Pending" || data.evaluator === null) return "-";
      return data.evaluator.name;
    },
    handleChangePage(page) {
      this.query.page = page;
      this.getList();
    },
    handleEssayButton(row) {
      if (row.status === "Pending") return "Corrigir";
      else return "Redação";
    },
    handleSendButton(row) {
      return !(
        row.status === "Correcting" &&
        this.$store.state.user.id === row.evaluator.id
      );
    },
    openCorrectionForm(row) {
      this.showDialog = true;
      this.essayId = row.id;
      this.editCorrection = false;
    },
    handleDialog(value) {
      this.showDialog = value;
      this.getList();
    },
    handleDialogInfo(value) {
      this.showDialogInfo = value;
      this.getList();
    },
    handleEditCorrection(essayId) {
      this.showDialog = true;
      this.essayId = essayId;
      this.editCorrection = true;
    },
    handleThemeTitle(row) {
      if (row.material_title) return row.material_title;
      if (row.theme != null && row.theme.title) return row.theme.title;
      return "Tema livre";
    },
    handleThemeUrl(row) {
      if (row.theme) return `/temas/${row.theme.id}`;
      return row.material;
    },
    handleClickedRow(row) {
      if (this.canSeeUserPage) {
        this.$router.push({
          name: "UserPage",
          params: { id: row.user_id },
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start

.essays-content
  max-width: 1400px
  width: 90%
  margin-top: 30px

.table-buttons
  display: flex
  justify-content: space-around

.status-component
  color: white
  padding: 5px
  border-radius: 7px 7px

  &-Pending
    @extend .status-component
    background-color: #409EFF !important

  &-Revised
    @extend .status-component
    background-color: #6de42b !important

  &-Correcting
    @extend .status-component
    background-color: #ffb300 !important

.essay-router-item
  cursor: pointer
  color: inherit
  font-weight: 700
  word-break: break-word

.primary-row
  color: #5b3cc4

.success-row
  color: #2e8200

.warning-row
  color: #9c6e00
</style>
