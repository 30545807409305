<template>
  <div class="container">
    <div v-loading="loading" class="content class-content">
      <el-row align="middle">
        <el-col :xs="0" :sm="3" :md="3" :lg="3" :xl="3">
          <img src="@/assets/logo_plataforma.png" class="header-logo" />
        </el-col>
        <el-col
          :xs="24"
          :sm="20"
          :md="20"
          :lg="20"
          :xl="20"
          class="video-details"
        >
          <h2>{{ video.title }}</h2>
          <br />
          <p style="font-size: 15px">
            {{ video.description }}
          </p>
        </el-col>
      </el-row>
      <div class="video-container">
        <div v-if="loadingVideo">
          <LoadingMessage message="Carrregando vídeo" class="loading-message" />
        </div>
        <div v-if="showErrorMessage">
          <p>
            Ocorreu algum erro ao tenta carregar vídeo, atualize a página para
            tentar novamente
          </p>
        </div>
        <vimeo-player
          v-if="loadSuccessfully"
          ref="player"
          :video-id="videoId"
          :options="options"
          @ready="onReady"
          @error="onError"
          @progress="onProgress"
        />
      </div>
      <div class="video-bottom">
        <el-button type="primary" @click="handleDownload">
          Baixar Material da Aula
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import errors from "@/traits/errors";
import LoadingMessage from "@/components/LoadingMessage";

export default {
  name: "VideoPage",
  props: {
    id: {
      required: true,
    },
  },
  components: { LoadingMessage },
  data() {
    return {
      loading: false,
      loadingVideo: true,
      showErrorMessage: false,
      loadSuccessfully: false,
      video: {},
      options: {
        responsive: true,
        speed: true,
        title: false,
        transparent: false,
        byline: false,
        portrait: false,
        color: "faa307",
      },
      sendedWatch: false,
      sending: false,
    };
  },
  created() {
    this.fetchVideo(this.id);
  },
  computed: {
    videoId() {
      return this.video.vimeo_id;
    },
  },
  methods: {
    fetchVideo(videoId) {
      this.loading = true;
      api
        .getVideo(videoId)
        .then(({ data: video }) => {
          this.video = video;
          this.loadSuccessfully = true;
        })
        .catch((e) => {
          errors.handleError(this, e);
          this.loadSuccessfully = false;
        })
        .finally(() => (this.loading = false));
    },
    onReady() {
      this.loadingVideo = false;
    },
    onError(event, data, player) {
      this.loadingVideo = false;
      this.showErrorMessage = true;
      console.log(event, data, player);
    },
    onProgress(event, data, player) {
      if (this.mustSendWatchStatus(event)) this.sendWatchStatus();
      console.log(event, data, player);
    },
    handleClick() {
      this.$refs.player.play();
    },
    handleDownload() {
      window.open(this.video.material, "_blank");
    },
    mustSendWatchStatus(event) {
      return event.percent > 0.85 && !this.sendedWatch && !this.sending;
    },
    sendWatchStatus() {
      this.sending = true;
      api
        .watchVideo(this.id)
        .then(({ data }) => {
          this.sendedWatch = true;
          console.log(data);
        })
        .catch((err) => {
          errors.handleError(this, err);
        })
        .finally(() => (this.sending = false));
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start

.class-content
  width: 95%
  margin: 20px
  padding: 10px
  max-width: 1400px
  margin-top: 80px

.video-container
  position: relative
  text-align: center

.header-logo
  width: 100%
  object-fit: cover
  padding-right: 15px

.video-details
  text-align: justify
  padding-top: 10px

.video-bottom
  padding-top: 20px
  display: flex
  justify-content: flex-end

.loading-message
  color: black !important
  padding: 40px
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
</style>
