<template>
  <div class="color-content">
    <div class="color-circle" :style="backgroundColor"></div>
    <div>{{ description }}</div>
  </div>
</template>

<script>
export default {
  name: "Colorlabel",
  props: {
    color: {
      required: true,
    },
    description: {
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    backgroundColor() {
      return `background: ${this.color}`;
    },
  },
};
</script>

<style lang="sass" scoped>
.color-content
  margin: 5px
  display: flex
  align-items: center

.color-circle
  border-radius: 50%
  width: 20px
  min-width: 20px
  height: 20px
  min-height: 20px
  margin: 5px
</style>