<template lang="html">
  <div class="container">
    <div class="content login-content">
      <img width="200px" src="@/assets/logo_plataforma.png" />
      <h2>Entrar</h2>
      <el-form ref="form" :model="form" :rules="rules" hide-required-asterisk>
        <el-form-item label="E-mail" prop="email">
          <el-input
            v-model="form.email"
            placeholder="Digite seu E-mail"
          ></el-input>
        </el-form-item>
        <el-form-item label="Senha" prop="password">
          <el-input
            v-model="form.password"
            type="password"
            placeholder="Digite sua senha"
            @keyup.enter.native="submit"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submit">Login</el-button>
      <div class="form-item">
        <span>
          Ainda não tem uma conta?
        </span>
        <router-link to="/cadastro">Cadastre-se</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../services/api'
import { mapMutations } from 'vuex'
import notify from '../../traits/notify'

export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      rules: {
        email: [
          {
            required: true,
            message: 'Digite um endereço de e-mail',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Por favor, digite um e-mail válido',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'Digite uma senha',
            trigger: 'blur',
          },
          {
            min: 6,
            max: 20,
            message: 'Senha deve ter entre 6 e 20 carácteres',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    ...mapMutations([
      'updateAccessToken',
      'updateUser',
      'updateRoles',
      'updatePermissions',
    ]),
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) this.login()
      })
    },
    login() {
      api
        .authenticate(this.form.email, this.form.password)
        .then((response) => {
          notify.sucessNotification(
            this,
            'Login Realizado',
            'Procedimento realizado com sucesso'
          )
          const { data } = response
          localStorage.setItem('accessToken', data.token.token)
          this.updateAccessToken(data.token.token)
          this.updateUser(data.user)
          this.updateRoles(data.roles)
          this.updatePermissions(data.permissions)
          this.$router.replace('/')
        })
        .catch((e) => {
          if (e.response.status === 403) {
            notify.errorNotification(
              this,
              'Login não autorizado',
              'Contate o Administrador'
            )
          } else if (e.response.status === 404) {
            notify.errorNotification(
              this,
              'Usuário não foi encontrado',
              'Verifique seus dados e tente novamente'
            )
          } else {
            notify.errorNotification(
              this,
              'Ocorreu Algum Erro',
              'Verifique seus dados'
            )
          }
        })
    },
    viewtoken() {
      console.log(this.$store.state)
      console.log(localStorage.getItem('accessToken'))
    },
  },
}
</script>

<style lang="sass" scoped>
.container
  width: 100%
  min-height: 89vh
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.login-content
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  width: 90%
  max-width: 450px
  padding: 40px 40px

.form-item
  margin-top: 10px

.login-button
  margin-top: 20px
  width: 120px
</style>
