<template>
  <div class="container">
    <PageHeader title="Todas as aulas">
      <el-input
        v-model="query.keyword"
        placeholder="Buscar"
        style="width: 180px; margin: 10px 10px"
        @keyup.enter.native="handleFilter"
      />
      <el-select
        v-model="query.playlist_id"
        placeholder="Selecione o módulo"
        @change="handleFilter"
        filterable
        clearable
      >
        <el-option
          v-for="playlist in playlists"
          :key="playlist.id"
          :label="playlist.title"
          :value="playlist.id"
        >
        </el-option>
      </el-select>
      <el-button
        type="primary"
        icon="el-icon-search"
        style="width: 120px; margin: 10px 10px"
        @click="handleFilter"
        >Buscar</el-button
      >
    </PageHeader>
    <LoadingMessage v-if="loading" style="margin-top: 200px" />
    <div v-else class="videos-list">
      <VideoCardMini
        v-for="video in videos"
        :key="video.id"
        :video="video"
        :playlist="video.playlist"
        :is-checked="alreadyWatched(video)"
        @updateList="fetchVideos"
      />
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
// import notify from "@/traits/notify";
import errors from "@/traits/errors";
import PageHeader from "@/components/PageHeader";
import VideoCardMini from "@/components/VideoCardMini";
import LoadingMessage from "@/components/LoadingMessage";

export default {
  name: "ListVideos",
  components: { PageHeader, VideoCardMini, LoadingMessage },
  data() {
    return {
      loading: false,
      videos: [],
      query: {
        keyword: "",
        playlist_id: "",
      },
      playlists: [],
      watchedVideos: [],
    };
  },
  created() {
    this.fetchVideos();
    this.fetchPlaylists();
    this.fetchWatchedVideos();
  },
  methods: {
    handleFilter() {
      this.query.page = 1;
      this.fetchVideos();
    },
    fetchVideos() {
      this.loading = true;
      api
        .getVideos(this.query)
        .then(({ data }) => (this.videos = data.data))
        .catch((e) => errors.handleError(this, e))
        .finally(() => (this.loading = false));
    },
    fetchPlaylists() {
      api
        .getPlaylists()
        .then(({ data: playlists }) => (this.playlists = playlists))
        .catch((e) => errors.handleError(this, e));
    },
    fetchWatchedVideos() {
      api
        .getVideosWatchedByMe()
        .then(({ data: videos }) => (this.watchedVideos = videos))
        .catch((e) => errors.handleError(this, e));
    },
    alreadyWatched(video) {
      return this.watchedVideos.some((element) => element.id === video.id);
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  display: flex
  flex-direction: column
  flex-flow: wrap
  justify-content: center

.videos-list
  align-items: flex-start
  justify-content: center
  display: flex
  width: 100%
  flex-flow: wrap
  max-width: 1300px
</style>
