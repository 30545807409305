<template>
  <div class="container">
    <div class="content config-content">
      <h2 style="margin: 10px 10px">Configurações</h2>
      <el-form
        ref="form"
        :model="alert"
        label-position="top"
        class="custom-form"
        :rules="rules"
      >
        <el-form-item class="custom-form-item">
          <div class="item-config">
            <h4>Envio de redações</h4>
            <el-switch
              v-model="essaySubmission.status"
              active-text="Ativo"
              inactive-text="Bloqueado"
            >
            </el-switch>
          </div>
        </el-form-item>
        <el-form-item class="custom-form-item">
          <div class="item-config">
            <h4>Alerta na tela inicial</h4>
            <el-switch
              v-model="alert.status"
              active-text="Ativo"
              inactive-text="Oculto"
            >
            </el-switch>
          </div>
        </el-form-item>
        <el-form-item class="custom-form-item">
          <div class="item-config flex-column">
            <h4>Título do Alerta</h4>
            <el-input placeholder="Título do Alerta" v-model="alert.title" />
          </div>
        </el-form-item>
        <el-form-item class="custom-form-item">
          <div class="item-config flex-column">
            <h4>Mensagem do Alerta</h4>
            <el-input
              placeholder="Mensagem do Alerta"
              type="textarea"
              :rows="4"
              v-model="alert.message"
            />
          </div>
        </el-form-item>
        <el-form-item class="custom-form-item">
          <div class="item-config flex-column">
            <h4>Título do link</h4>
            <el-input placeholder="Título do link" v-model="alert.linkTitle" />
          </div>
        </el-form-item>
        <el-form-item class="custom-form-item" prop="link">
          <div class="item-config flex-column">
            <h4>Link</h4>
            <el-input placeholder="Link" v-model="alert.link">
              <template slot="prepend">Https://</template>
            </el-input>
          </div>
        </el-form-item>

        <div class="config-footer">
          <el-button v-loading="loading" type="primary" @click="submit">
            Salvar Alterações
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import errors from "@/traits/errors";
import notify from "@/traits/notify";

export default {
  name: "Config",
  data() {
    return {
      loading: false,
      essaySubmission: {
        status: false,
      },
      alert: {
        status: false,
        title: "",
        message: "",
        link: "",
        linkTitle: "",
      },
      rules: {
        link: {
          type: "url",
          message: "Adicione um link válido",
        },
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      api
        .getConfig()
        .then(({ data }) => {
          console.log(data);
          this.essaySubmission.status = data.essaySubmissionStatus;
          this.alert = data.alert;
        })
        .catch((err) => {
          errors.handleError(this, err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) this.saveChanges();
      });
    },
    saveChanges() {
      this.loading = true;
      const config = {
        essaySubmission: this.essaySubmission,
        alert: this.alert,
      };
      console.log(config);
      api
        .setConfig(config)
        .then(() => {
          notify.sucessNotification(
            this,
            `Configurações alteradas com sucesso`,
            "A alteração já surte efeito nesse instante"
          );
        })
        .catch((err) => {
          errors.handleError(this, err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start

.config-content
  width: 90%
  margin: 20px
  max-width: 1000px
  margin-top: 80px

.custom-form-item
  margin-bottom: 5px !important

.item-config
  display: flex
  justify-content: space-between
  align-items: center

.flex-column
  align-items: flex-start
  flex-direction: column

.config-footer
  margin: 10px 10px
  display: flex
  justify-content: flex-end

.custom-form
  max-width: 1000px
  padding: 10px 20px
</style>
