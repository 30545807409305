<template>
  <div class="container">
    <div class="content playlist-content">
      <div style="margin: 20px">
        <h2>{{ !isEdited ? "Adicionar novo módulo" : "Editar módulo" }}</h2>
      </div>
      <el-form
        ref="form"
        :model="playlist"
        :rules="rules"
        hide-required-asterisk
      >
        <el-form-item label="Título" prop="title">
          <el-input
            v-model="playlist.title"
            placeholder="Digite o título do módulo"
          ></el-input>
        </el-form-item>
        <el-form-item label="Descrição" prop="description">
          <el-input type="textarea" v-model="playlist.description"></el-input>
        </el-form-item>
        <el-form-item label="Duração" prop="duration">
          <el-input-number
            v-model="playlist.duration"
            :min="0"
            :max="500"
            :step="5"
          ></el-input-number>
        </el-form-item>
        <el-form-item prop="image">
          <Upload
            v-on:image="updateContent"
            type="image"
            label="Imagem do módulo"
            url="image"
            :accepted="'.png,.jpg,.jpeg'"
            :file="playlist.image"
          />
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" @click="validForm">{{
          isEdited ? "Salvar Alterações" : "Adicionar"
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import errors from "@/traits/errors";
import notify from "@/traits/notify";
import Upload from "@/components/Upload";

export default {
  name: "PlaylistForm",
  props: {
    id: {
      required: false,
      type: Number,
    },
  },
  components: {
    Upload,
  },
  data() {
    var validateFiles = (rule, value, callback) => {
      if (this.playlist.image === "") {
        callback(new Error("Adicione o arquivo de conteúdo"));
      } else if (this.loading) {
        callback(new Error("Aguarde os arquivos serem salvos no servidor!"));
      } else {
        callback();
      }
    };

    return {
      isEdited: this.id,
      playlist: {
        title: "",
        description: "",
        image: "",
        duration: 0,
      },
      rules: {
        title: {
          required: true,
          message: "Digite um título",
          trigger: "blur",
        },
        description: {
          required: true,
          message: "Digite uma descrição",
          trigger: "blur",
        },
        image: {
          validator: validateFiles,
          trigger: "blur",
        },
        duration: {
          required: true,
          message: "Digite a duração do módulo",
          trigger: "blur",
        },
      },
    };
  },
  created() {
    if (this.isEdited) {
      this.fetchData(this.isEdited);
    }
  },
  methods: {
    fetchData(playlistId) {
      api
        .getPlaylist(playlistId)
        .then(({ data: playlist }) => (this.playlist = playlist))
        .catch((e) => errors.handleError(this, e))
        .finally(() => (this.loading = false));
    },
    validForm() {
      this.$refs["form"].validate((valid) => {
        if (valid)
          this.isEdited ? this.updatePlaylist() : this.createPlaylist();
      });
    },
    createPlaylist() {
      api
        .createPlaylist(
          this.playlist.title,
          this.playlist.description,
          this.playlist.image,
          this.playlist.duration
        )
        .then(({ data }) => {
          notify.sucessNotification(
            this,
            `Módulo ${data.title} criado com sucesso`,
            "Novo módulo já pode ser accessado na lista de módulos"
          );
          this.resetForm();
          this.$router.replace({
            name: "ListPlaylists",
          });
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    updatePlaylist(){
      api
        .updatePlaylist(
          this.isEdited,
          this.playlist.title,
          this.playlist.description,
          this.playlist.image,
          this.playlist.duration
        )
        .then(({ data }) => {
          notify.sucessNotification(
            this,
            `Módulo ${data.title} atualizado com sucesso`,
            "O módulo já pode ser accessado na lista de módulos"
          );
          this.resetForm();
          this.$router.replace({
            name: "ListPlaylists",
          });
        })
        .catch((err) => {
          errors.handleError(this, err);
        });
    },
    updateContent(data) {
      this.playlist.image = data;
    },
    resetForm() {
      this.playlist.title = "";
      this.playlist.description = "";
      this.playlist.image = "";
      this.playlist.duration = "";
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  min-height: 89vh
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.playlist-content
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  max-width: 600px
  padding: 30px
</style>
