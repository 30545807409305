<template>
  <div class="pdf-container">
    <div class="pdf-buttons">
      <el-button v-if="showPages" type="primary" @click="handlePrevious" round>
        Página anterior
      </el-button>
      <a v-if="showPages"> {{ page }} / {{ numPages ? numPages : "∞" }} </a>
      <el-button v-if="showPages" type="primary" @click="handleNext" round>
        Próxima página
      </el-button>
      <el-button
        v-if="downloadAllowed && downloadPosition === 'top'"
        @click="handleDownload"
        type="primary"
        class="download-button"
        round
      >
        Baixar PDF
      </el-button>
    </div>
    <div class="pdf-content">
      <pdf
        :src="url"
        :page="page"
        :scale.sync="scale"
        :resize="true"
        style="width: 100%; text-align: center"
      >
        <template slot="loading" style="align-tex">
          Carregando Arquivo
        </template>
      </pdf>
    </div>
    <div
      class="pdf-buttons"
      v-if="downloadAllowed && downloadPosition === 'bottom'"
    >
      <el-button
        @click="handleDownload"
        type="primary"
        class="download-button"
        round
      >
        Baixar PDF
      </el-button>
    </div>
  </div>
</template>

<script>
import pdfvuer from "pdfvuer";

export default {
  name: "PDFViewer",
  props: {
    url: {
      type: String,
      required: true,
    },
    downloadAllowed: {
      type: Boolean,
      required: true,
    },
    downloadPosition: {
      type: String,
      required: true,
      default: "top",
    },
  },
  components: { pdf: pdfvuer },
  data() {
    return {
      page: 1,
      scale: 1,
      numPages: 1,
    };
  },
  created() {
    this.getPdf();
  },
  computed: {
    showPages: function () {
      return this.numPages > 1;
    },
  },
  methods: {
    handlePrevious() {
      if (this.page > 1) {
        this.page--;
      }
    },
    handleNext() {
      if (this.page < this.numPages) {
        this.page++;
      }
    },
    handleDownload() {
      window.open(this.url, "_blank");
    },
    getPdf() {
      pdfvuer.createLoadingTask(this.url).then((pdf) => {
        console.log(this.numPages);
        this.numPages = pdf.numPages;
        this.$forceUpdate();
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.pdf-container
  width: 100%

.download-button
  background: rgb(107, 65, 227)
  border-color: rgb(107, 65, 227)
  color: white

.pdf-content
  padding: 10px

.pdf-buttons
  margin: 10px
  display: flex
  justify-content: space-evenly
  align-items: center
  flex-flow: wrap
</style>