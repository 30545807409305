<template>
  <div class="container">
    <div class="content theme-form">
      <div style="margin-bottom: 20px">
        <h2>{{ !isEdited ? 'Adicionar novo tema' : 'Editar Tema' }}</h2>
      </div>
      <el-form ref="form" :model="theme" :rules="rules" hide-required-asterisk label-position="top">
        <el-form-item label="Título" prop="title">
          <el-input
            v-model="theme.title"
            placeholder="Digite o título do tema"
          ></el-input>
        </el-form-item>
        <el-form-item label="Subtitulo" prop="subtitle">
          <el-input type="textarea" v-model="theme.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="Vídeo de discussão temática" prop="related_video_id">
          <el-select
            v-model="theme.related_video_id"
            placeholder="Selecione o vídeo correspondente"
            filterable
            clearable
            style="display: flex"
          >
            <el-option
              v-for="video in videos"
              :key="video.id"
              :label="video.title"
              :value="video.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="files">
          <vs-row vs-justify="center" vs-align="center">
            <upload
              v-on:image="updateImage"
              type="image"
              label="Imagem"
              url="image"
              :accepted="'.jpg,.png'"
              :file="theme.image"
            />
            <upload
              v-on:image="updateContent"
              type="pdf"
              label="Conteúdo (PDF)"
              url="pdf"
              :accepted="'.pdf'"
              :file="theme.content"
            />
          </vs-row>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" @click="validForm">{{
          isEdited ? 'Salvar Alterações' : 'Adicionar'
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../services/api'
import notify from '../../traits/notify'
import errors from '../../traits/errors'
import Upload from '../../components/Upload'

export default {
  props: ['id'],
  components: {
    Upload,
  },
  data() {
    var validateFiles = (rule, value, callback) => {
      if (this.theme.content === '') {
        callback(new Error('Adicione o arquivo de conteúdo'))
      } else if (this.theme.image === '') {
        callback(new Error('Adicione uma imagem para esse tema'))
      } else if (this.loading) {
        callback(new Error('Aguarde os arquivos serem salvos no servidor!'))
      } else {
        callback()
      }
    }

    return {
      isEdited: this.id,
      loading: false,
      theme: {
        title: '',
        subtitle: '',
        content: '',
        image: '',
        related_video_id: null,
      },
      videos: [],
      rules: {
        title: {
          required: true,
          message: 'Digite um título',
          trigger: 'blur',
        },
        subtitle: {
          required: true,
          message: 'Digite um subtitulo',
          trigger: 'blur',
        },
        files: {
          validator: validateFiles,
          trigger: 'blur',
        },
      },
    }
  },
  created() {
    if (this.isEdited) {
      this.fetchData(this.isEdited)
    }
    this.fetchVideos()
  },
  methods: {
    fetchData(id) {
      api
        .getTheme(id)
        .then(({ data: theme }) => {
          this.theme = theme
        })
        .catch((err) => {
          errors.handleError(this, err)
        })
    },
    fetchVideos(){
     api
        .getVideos()
        .then(({ data }) => (this.videos = data.data))
        .catch((e) => errors.handleError(this, e))
    },
    validForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) this.submit()
      })
    },
    submit() {
      if (this.isEdited) {
        api
          .updateTheme(
            this.isEdited,
            this.theme.title,
            this.theme.content,
            this.theme.subtitle,
            this.theme.image,
            this.theme.related_video_id,
          )
          .then(({ data }) => {
            notify.sucessNotification(
              this,
              `Tema ${data.title} alterado com sucesso`,
              'A atualização já está disponível na lista de temas'
            )
            this.resetForm()
            this.$router.replace(`/temas/${data.id}`)
          })
          .catch((err) => {
            errors.handleError(this, err)
          })
      } else {
        api
          .createTheme(
            this.theme.title,
            this.theme.content,
            this.theme.subtitle,
            this.theme.image,
            this.theme.related_video_id,
          )
          .then(({ data }) => {
            notify.sucessNotification(
              this,
              `Tema ${data.title} criado com sucesso`,
              'Novo tema já pode ser accessado na lista de temas'
            )
            this.resetForm()
            this.$router.replace(`/temas/${data.id}`)
          })
          .catch((err) => {
            errors.handleError(this, err)
          })
      }
    },
    updateImage(data) {
      this.theme.image = data
    },
    updateContent(data) {
      this.theme.content = data
    },
    resetForm() {
      this.theme.title = ''
      this.theme.content = ''
      this.theme.subtitle = ''
      this.theme.image = ''
      this.theme.related_video_id = null
    },
  },
}
</script>

<style lang="sass" scoped>
.container
  min-height: 89vh
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.theme-form
  background-color: #fff
  padding: 30px
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

.form-item
  margin-bottom: 15px
</style>
