<template>
  <div>
    <el-dialog title="Envie sua redação" :visible.sync="activeDialog">
      <p style="margin-bottom: 20px">Escolha, em seus arquivos, a sua redação. Pode enviar o texto em formato jpg, png, pdf, docx.</p>
      <vs-row vs-justify="center" vs-align="flex-end">
        <el-form
          ref="form"
          :model="essay"
          :rules="rules"
          hide-required-asterisk
          class="my-essay-form"
        >
          <el-form-item prop="file">
            <Upload
              v-on:image="updateContent"
              type="essay"
              label="Redação"
              url="file"
              :accepted="'.jpg,.png,.docx,.pdf,.doc'"
            />
          </el-form-item>
          <el-form-item prop="textarea">
            <vs-textarea
              label="Deixe aqui alguma observação para o corretor"
              v-model="essay.textarea"
              width="300px"
            />
          </el-form-item>
        </el-form>
      </vs-row>
      <div align="center">
        <el-button type="primary" :disabled="sending" @click="validForm">{{buttonMessage}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Upload from "../../../components/Upload";
import api from "../../../services/api";
import notify from "../../../traits/notify";
import errors from "../../../traits/errors";

export default {
  name: "ErrayForm",
  props: ["showDialog", "themeId"],
  components: {
    Upload,
  },
  data() {
    var validateFile = (rule, value, callback) => {
      if (this.essay.file === "") {
        callback(new Error("Adicione o arquivo da redação"));
      } else {
        callback();
      }
    };

    return {
      activeDialog: false,
      sending: false,
      essay: {
        textarea: "",
        file: "",
      },
      rules: {
        subtitle: {
          required: false,
          message: "Digite um subtitulo",
          trigger: "blur",
        },
        file: {
          validator: validateFile,
          trigger: "blur",
        },
      },
    };
  },
  computed: {
    buttonMessage() {
      return this.sending ? "Enviando..." : "Enviar Redação";
    },
  },
  watch: {
    showDialog: function (val) {
      if (val) this.activeDialog = true;
    },
    activeDialog: function (val) {
      if (!val) this.$emit("updateDialog", false);
    },
  },
  methods: {
    updateContent(data) {
      this.essay.file = data;
    },
    validForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) this.submit();
      });
    },
    submit() {
      if (!this.sending) {
        this.sending = true;
        api
          .createEssay(this.essay.file, this.essay.textarea, this.themeId)
          .then(() => {
            this.sending = false;
            notify.sucessNotification(
              this,
              `Sua redação foi enviada com sucesso`,
              'Aguarde a correção. Ela será enviada em até 2 dias. Você poderá acessar suas correções em "Minhas Redações"'
            );
            this.resetForm();
            this.activeDialog = false;
            this.$store.dispatch("fetchUser");
            this.$router.replace(`/temas`);
          })
          .catch((err) => {
            this.sending = false;
            errors.handleError(this, err);
          });
      }
    },
    resetForm() {
      this.essay.textarea = "";
      this.essay.file = "";
    },
  },
};
</script>

<style lang="sass" scoped>
.my-essay-form
  display: flex
  flex-direction: column
  align-items: center
</style>
